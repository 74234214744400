
const Seasonal = (initial = {}) => {
  const struct = {
    season: ''
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  seasons: [{ id: 'all', name: 'All Season' }, { id: 'spring', name: 'Mild Season' }, { id: 'summer', name: 'Beach Season' }, { id: 'winter', name: 'Harsh Climate' }]
}
Seasonal.ICON_STRING = 'fa-umbrella-beach';
export default Seasonal;
