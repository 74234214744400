/**
 * Provide forecast data for supplied date duration,
 * calling { lat, lon } https://api.weather.gov/points/39.7456,-97.0892
 * gives us response.properties.forecast
 * which provides highs and lows for the location
 * "https://api.weather.gov/gridpoints/{lon}/{lat}"
 * response.properties.elevation { value, unitCode }
 * response.properties.periods [{ startTime, endTime, isDaytime, temperature, temperatureUnit,temperatureTrend, windSpeed, windDirection, icon, shortForecast, detailedForecast }]
 * if longer than 13 days, send multiple requests to pick up the next weeks
 */
import { put, takeLatest, all } from 'redux-saga/effects';
import { GEO_SUCCESS, FORECAST_SUCCESS, FORECAST_FAIL, GEO_FAIL, FORECAST_GET } from '../reducers/forecast.reduce';
import { parseWeatherGovForecast } from '../helpers/forecast.helper';
const weatherPortal = 'https://api.weather.gov/';

function* fetchForecast(payload) {
  const pointUrl = `/points/${payload.coords[1]},${payload.coords[0]}`;
  const headers = new Headers({
    'Accept': 'application/geo+json',
    'Content-Type': 'application/json',
    'User-Agent': 'packitweatherrequest@mokee.org'
  });
  
  const geoPoint = yield fetch(weatherPortal + pointUrl, headers).then(response => response.json());
  if (geoPoint) {
                    yield put({ type: GEO_SUCCESS, id:payload.id, geo: geoPoint });
    const forecast = yield fetch(geoPoint.properties.forecast).then(response => response.json())
    if (forecast) {
      const parsed = parseWeatherGovForecast(
        forecast.properties,
        `${geoPoint.properties.gridX},${geoPoint.properties.gridY}`
      );
      const forecastByGrid = {...parsed, [`${payload.coords[0]},${payload.coords[1]}`]:parsed[`${geoPoint.properties.gridX},${geoPoint.properties.gridY}`] };
      yield put({ type: FORECAST_SUCCESS, forecast: {id:payload.id, ...forecastByGrid }});
    } else {
      yield put({ type: FORECAST_FAIL, forecast: { id: geoPoint.properties.forecast } });
    }
  } else {
    yield put({ type: GEO_FAIL });
  }
}

function* actionWatcher() {
  yield takeLatest(FORECAST_GET, fetchForecast)
}

export default function* rootSaga() {
  yield all([
    actionWatcher(),
  ]);
}