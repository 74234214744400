
import React from 'react';
import Spinner from './spinner';
import Panel from './panel';

const PanelLoading = props => {
  return (
    <Panel className="loading">
      <Spinner />
    </Panel>
  );
}

export default PanelLoading
