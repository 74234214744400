/**
 * HomeView is a dashboard of sorts, giving 
 * you a tally of your items and acheivements within the app
 * there should be a 'well' with todos
 * adding a water bottle, adding a tent, etc.
 * these all get pulled from 
 * i could see a virtual leaderboard based on remote submissions
 * 
 */
import { connect } from 'react-redux';
import { HomeDashboard } from './home-dashboard';
import { editItem } from '../../reducers/item.reduce';
import { inspect } from '../../helpers/category.helper';
import { getActiveTrip } from '../../helpers/trip.helper';

const mapStateToProps = state => {
    return {
        //this should really be a filter action for items in inventories with
        //an matching owner id
        activeTrip: getActiveTrip(state),
        items: Object.keys(state.inventories).map(i => state.inventories[i]),
        activeAccount: state.activeAccount,
        featureMap: inspect(state)
        // view:state.view
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onContinue: pv => {
            dispatch(editItem(pv))
        }
    };
}
/**
 * Home View does not have different states as components
 * but it can have different layout schemes,
 * do we do this as a configuration or as component template?
 */
export const homeView = connect(mapStateToProps, mapDispatchToProps)(HomeDashboard)
export const PAGE_HOME = 'homePage';
export default homeView
