
const AccountFactory = (initial = {}) => {
  const struct = {
    //CHANGE id is removed from the profile
    //as the future account may be { id:12142443, data:this-object}
    // id:-1,
    name: '',
    //CHANGE inventory is now an id reference, could also be an api link
    //https://packit.mokee.org/inventory?id=256567897
    inventory: [],
    packs: [],
    //app carries the users achievement states
    app: {
      //create an item at least once with helpers
      itemCreate: false,
      //walk a user through editing 
      itemEdit: false,
      //main.js will need to punch in a boolean for each component editor
      itemCategoryEdit: {},
      //at least one item with a category of 104
      packCreate: false,
      //create a secondary profile for a pack
      packProfiled: false
    },
    //created will now be a meta data value
    //created:-1,
    avatar: '',
    gender: 'U',
    trips: [],
    weighs: {}

  };

  return { ...struct, ...initial };
};

export default AccountFactory;
