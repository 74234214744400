import { VolumeOut } from '../types/units';
import Displaces from '../types/displaces.value';
import { changeInspector } from '../utils/change-inspector';

/**
 * Inspector should force the calculation of any derived values
 * For displaces this is calculating the displacement volume
 */
export const inspect = (o) => {
  return Displaces({ ...o, displaces: volume(o) });
}

// updateState will inspect an object and reports to handle displaces
export const updateState = changeInspector('displaces', inspect);

// return volume of a rectangle given length width height in Inches
export const volume = obj => {
  if (obj && obj.width && obj.height && obj.length && obj.unit) {
    let conv = Math.pow(unit(obj.unit), 3);
    let factor = 61.024;//Liters obj.displacesUnit
    return (conv * (obj.length * obj.width * obj.height)) / factor;
  }
  return 0;
};

//only supporting inches and cms
export const unit = n => {
  if (n === 'cm') return 0.4;
  return 1;
}
/* deprecated */

export const isDisplaced = ({ length, width, height, unit, displacesUnit, displaces }) => {
  return (unit && displacesUnit && displaces >= 0 && length >= 0 && width >= 0 && height >= 0);
};

/**
 * in order to be valid, volume must be more than zero.
 */
export const validate = (o = {}) => {
  return (isDisplaced(o) && o.displaces > 0)
}

/**
 * Generates an object for components to show NumofLiters
 * @param {*} o 
 */
export const display = (o) => {
  let r = VolumeOut();
  if (isDisplaced(o)) {
    r.unit = o.displacesUnit;
    r.value = volume(o);
  }
  return r;
};

export default {
  inspect,
  validate,
  updateState,
  isDisplaced,
  display,
  volume
}
