
const Shelter = (initial = {}) => {
  const struct = {
    complete: false,
    type: "",
    person: 1,
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  type: [{ id: 'bivy', name: 'Bivy' }, { id: 'tarp', name: 'Tarp Tent' }, { id: 'tent', name: 'Pole Tent' }, { id: 'hammock', name: 'Hammock' }]
};

Shelter.ICON_STRING = 'fa-umbrella';
export default Shelter;
