
import { crudArrayReducer, addToArray, editToArray, removeFromArray } from '../utils/crud-reducer';

export const CATEGORY_ADD = 'onCategoryAdd';
export const CATEGORY_EDIT = 'onCategoryEdit';
export const CATEGORY_REMOVE = 'onCategoryRemove';

//Selectors 
export const getCategories = state => state.categories;
export const getCategoryById = (state, id) =>
    getCategories(state).find(ac => ac.id === id);

export const addCategory = data => {
    return {
        type: CATEGORY_ADD,
        data
    }
}
export const editCategory = data => {
    return {
        type: CATEGORY_EDIT,
        data
    }
}
export const removeCategory = data => {
    return {
        type: CATEGORY_REMOVE,
        data
    }
}

/**
 * categories is a data repo, in the future custom tailoring
 * a category will allow for better filtering of features
 */
export const categories = crudArrayReducer('categories', {
    [CATEGORY_ADD]: addToArray,
    [CATEGORY_EDIT]: editToArray,
    [CATEGORY_REMOVE]: removeFromArray,
});

export default categories
