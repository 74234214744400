
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WellFlag from '../../components/well-flag';
// import { Link } from 'react-router-dom';
import ItemElement from '../../components/item-element';
import { getItemsByCategory } from '../../helpers/item.helper';
import { inspectFeatureMap } from '../../helpers/category.helper';
import { withRouter } from 'react-router-dom';
import { SelectComp } from '../../components/inputs';
import Panel from '../../components/panel';

export const SYSTEMS_MANAGE = 'systemManageView';

class TripManage extends Component {

  static propTypes = {
    featureMap: PropTypes.object,
    categories: PropTypes.array,
    history: PropTypes.any,
    activeAccount: PropTypes.object.isRequired,
    inventory: PropTypes.array.isRequired,
    message: PropTypes.string,
    onContinue: PropTypes.func,
    logo: PropTypes.string,
    messages: PropTypes.object
  };

  static defaultProps = {
    message: `This is where your stuff would show its value`,
    items: [],
    history: [],
    featureMap: {},
    messages: {
      emptyCategory: `Looks like you need to add some of this category`, noItems: `Looks like you need to add some items before we get started. Knowing what resources you have in inventory is more than just a checklist, it's a MacGuyver list!    Besides, it's quick and you can edit just about everything later`,
      noPacks: `Backpacks are essential to making use of this app, without it there is no constraints to optimize for`,
      noOutfit: `While you have a few clothing items, you don't quite have a full, let alone mismatched outfit. Adding more clothes to your inventory will make sure you don't go naked on your next trip.`
    }
  };
  //  inventory, activeAccount, history, featureMap, messages, onContinue}) => {
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      itemList: getItemsByCategory('', props.inventory)
    }
  }

  /* redirect to edit view for item */
  _itemLink = event => {
    if (event) {
      this.props.history.push(`/item/edit/${event}`);
    }
  }

  /* new item view */
  _newItem = () => {

    this.props.history.push(`/item/create`);
  }

  onSelectChange = event => {
    if (event.target.value !== 'default') {
      const n = getItemsByCategory(event.target.value, this.props.inventory);
      this.setState({ category: event.target.value, itemList: n });
    } else {
      this.setState({ category: '', itemList: this.props.inventory });
    }
  }

  /* 
    generate item list, 
    consider pagination, sorting by type
  */
  _itemWindow = () => (
    this.state.itemList.map(
      item => <ItemElement onEdit={this._itemLink} key={item.id} {...item} />)
  )

  wank = a => {
    let t = Object.keys(a).map(d => d.parent !== -1)
    return t.length;
  }
  //this is a primitive call 
  hmm = inspectFeatureMap(this.props.featureMap, 'fillable', this.wank)

  render() {
    /* no items prompts notification to user that they can't do shit without any items */
    const _newAccount = this.props.inventory.length || 0;
    const emptyCategory = this.state.itemList.length || 0;

    return (
      <Panel className="activity-systems panel-fill" >
        <WellFlag >
          You have no items go make some first, otherwise i have nothing to say to you
        </WellFlag>
        <WellFlag >
          Systems keep track of the configurations we use on a trip and allow us to impose those selections onto the next trip of a similar type. It saves a lot of trouble with entry and selections
          TODO display cards of systems, disable cards that dont have enough data to be viewed. (no outfits, no items, no items of specific category)
        </WellFlag>
      </Panel>);
  }
}


export default withRouter(TripManage)
