
/**
  fillable declaration
*/

/* output for color feedback and positive value*/
export const FillOut = () => {
  return {
    indicator: 'danger',
    percent: 0,
    refill: 0
  };
}

/* default output for volume related views*/
export const VolumeOut = () => {
  return {
    value: 0,
    unit: 'L'
  };
}
/* default output for volume related views*/
export const MassOut = () => {
  return {
    value: 0,
    unit: 'lb'
  };
}
/* default output for volume related views*/
export const MassCount = () => {
  return {
    accuracy: 0,
    value: 0,
    unit: 'lb'
  };
}
/* default output for volume related views*/
export const VolumeCount = () => {
  return {
    accuracy: 0,
    value: 0,
    unit: 'L'
  };
}

const unitModule = {
  FillOut,
  VolumeOut,
  VolumeCount,
  MassOut,
  MassCount
};


export default unitModule;
