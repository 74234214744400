import React from 'react';
import PropTypes from 'prop-types';
import ToggleGenerator from '../hoc/toggle-generator';


const _select = ops => (
  ops.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
  )
  export const ToggleComp = ({formId, label, value, options, onBlur, onChange}) => (
    
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">{label}</span>
      </div>
      {
        options.map(o => 
          <React.Fragment key={o.id} >
            <label className="input-group-prepend">{o.name}
            <input type="radio" id={o.id} name={formId} value={o.id} onChange={onChange} onBlur={onBlur} checked={value === o.id} /></label>
          </React.Fragment>
        )
      }
    </div>
  )
  
    ToggleComp.propTypes = {
      value:PropTypes.any,
      formId:PropTypes.string,
      label:PropTypes.string,
      options:PropTypes.array,
      onChange:PropTypes.func,
      onBlur:PropTypes.func
    }
export const CheckBoxComp = ({formId, label, value, onBlur, onChange}) => {
  const convertToNumber = event => {
    onChange({target:{value:event.target.checked, name:formId}});
  }
  return (
  <div className="input-group">
    <div className="input-group-prepend">
      <div className="input-group-text">
        <input type="checkbox" id={formId} name={formId} onChange={convertToNumber} onBlur={onBlur} value={value} defaultChecked={value}/>
        </div></div>
      <div className="form-control">{label}</div>
  </div>
);
}

  CheckBoxComp.propTypes = {
    value:PropTypes.any,
    formId:PropTypes.string,
    label:PropTypes.string,
    onChange:PropTypes.func,
    onBlur:PropTypes.func
  }

CheckBoxComp.defaultProps = {  }

export const RadioComp = ({formId, label, value, options, onBlur, onChange}) => (
    <React.Fragment>
    {
      options.map(o => 
        <div className="input-group" key={o.id} >
          <div className="input-group-prepend">
          <span className="input-group-text">{o.name}</span>
          </div>
          <input type="radio" id={o.id} name={formId} value={o.id} onChange={onChange} onBlur={onBlur} checked={value === o.id} />
        </div>
      )
    }
    </React.Fragment>
)

  RadioComp.propTypes = {
    value:PropTypes.any,
    formId:PropTypes.string,
    label:PropTypes.string,
    options:PropTypes.array,
    onChange:PropTypes.func,
    onBlur:PropTypes.func
  }
  RadioComp.defaultProps = {
    options:[]
  }

export const SelectComp = ({formId, label, options, value, multiple, onBlur, onChange}) => (

     <div className="input-group">
      <div className="input-group-prepend" id={`select_form_${formId}`}>
      <span className="input-group-text">{label}</span>
      </div>
      <select className="custom-select" multiple={multiple} value={value} name={formId} onChange={onChange} onBlur={onBlur} aria-label={label} aria-describedby={`select_form_${formId}`} required>
      
      <option key='default' value='default'> </option>
      { _select(options) }</select>
     </div>
  );

  SelectComp.propTypes = {
    multiple:PropTypes.bool,
    value:PropTypes.any,
    formId:PropTypes.string,
    label:PropTypes.string,
    options:PropTypes.array,
    onChange:PropTypes.func,
    onBlur:PropTypes.func
  }
  SelectComp.defaultProps = {
    multiple:false
  }

export const TextComp = ({formId, label, value, onChange, onBlur, optional, read}) => (
  
     <div className="input-group">
      <div className="input-group-prepend" id={`text_form_${formId}`}>
        <span className="input-group-text">{label}</span>
      </div>
      { (read) ?
      <input type="text" name={formId} onChange={onChange} onBlur={onBlur} className="form-control" value={value} placeholder={optional} aria-label={label} aria-describedby={`text_form_${formId}`} readOnly />:
      <input type="text" name={formId} onChange={onChange} onBlur={onBlur} className="form-control" value={value} placeholder={optional} aria-label={label} aria-describedby={`text_form_${formId}`}/>
      }
    </div>
  );
  TextComp.propTypes = {
    read:PropTypes.bool,
    formId:PropTypes.string,
    label:PropTypes.string,
    value:PropTypes.any,
    optional:PropTypes.string,
    onChange:PropTypes.func,
    onBlur:PropTypes.func
  }
  
  /**
   * hardcoded min to 0 for our purposes, although temps below zero...
   * perhaps leave this to a validator at a higher level hmm?
   * @param {*} param0 
   */
export const NumberComp = ({formId, label, value, onBlur, onChange, min, max, step, read}) => (
  
     <div className="input-group">
      <div className="input-group-prepend" id={`number_form_${formId}`}>
        <span className="input-group-text" >{label}</span></div>
      { (read)?
      <input type="number" name={formId} className="form-control" value={value} aria-label={label} aria-describedby={`number_form_${formId}`}readOnly />:
      <input type="number" name={formId} onChange={onChange} onBlur={onBlur} className="form-control" step={step} min={min} max={max} value={value} aria-label={label} aria-describedby={`number_form_${formId}`}/>
      }
     </div>
  );
  NumberComp.propTypes = {
    read:PropTypes.bool,
    formId:PropTypes.string,
    label:PropTypes.string,
    value:PropTypes.number,
    onChange:PropTypes.func,
    onBlur:PropTypes.func,
    min:PropTypes.string,
    max:PropTypes.string,
    step:PropTypes.string
  }

  NumberComp.defaultProps = {
    min:"0",
    max:"9999",
    step:"any"
  }
  
export const ColorComp = ({formId, label, value, onBlur, onChange}) => (
  
  <div className="input-group">
   <div className="input-group-prepend" >
   <span className="input-group-text" >{label}</span></div>
   <input type="color" className="form-control" name={formId} value={value} onChange={onChange} onBlur={onBlur} />
  </div>
);
ColorComp.propTypes = {
  formId:PropTypes.string,
  label:PropTypes.string,
  value:PropTypes.string,
  onChange:PropTypes.func,
  onBlur:PropTypes.func
}
ColorComp.defaultProps = {
  value:'#000000',
}

export const SliderComp = ({min, max, formId, label, value, onBlur, onChange}) => (
  
  <div className="input-group">
     <div className="input-group-prepend">
     <span className="input-group-text">{label}</span></div>
     <input type="range" className="form-control" min={min} max={max} name={formId} value={value} onChange={onChange} onBlur={onBlur} />
     <div className="input-group-append">
     <span className="input-group-text">{value}</span></div>
   </div>
  );

  SliderComp.propTypes = {
    min:PropTypes.number.isRequired,
    max:PropTypes.number.isRequired,
    formId:PropTypes.string,
    label:PropTypes.string,
    value:PropTypes.number,
    onChange:PropTypes.func,
    onBlur:PropTypes.func
  }
  SliderComp.defaultProps = {
    value:0,
    min:0,
    max:100
  }

  export const ToggleSliderComp = ToggleGenerator(SliderComp);
  