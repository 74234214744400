import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AccountModal = ({ list }) => (
  <div className="list-group">

    {list.map(o =>
      <Link key={o.link} to={o.link} className="list-group-item" activeclassname="item-active" title={o.title}>
        {o.text}
        <span className='badge badge-primary float-right'>{o.badge}</span>
      </Link>
    )
    }
  </div>
);


AccountModal.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      badge: PropTypes.any
    }))

};

export default AccountModal;
