import { coerceTypes } from './coerce-types';

/**
 * TODO: this only handles one name value pair before sending to cb
 * change so that we can batch inspect before hitting cb
 * @param {*} module 
 * @param {*} i 
 */
export const changeInspector = (module = '', i = () => { }) =>
  (spec = {}) =>
    ({ name, value }, cb = () => { }) => {

      if (name !== undefined && value !== undefined) {
        console.log(`update ${module}`);
        let change = coerceTypes({ [name]: value }, spec);
        cb({ [module]: i(change) });
      }
    }
/**
 * Designates an inspector to use that takes an items module tree as argument
 * a bypass is required for sub-feature parameter changes that affect the root
 * feature properties
 * @param {*} featureName 
 * @param {*} i 
 */
export const changeFeatureInspector = (featureName = '', inspector = () => { }) =>
//feature initialization with 
//spec - hash of field names
//all - this is the whole module for cross validation
//cb - notifies the composition layer
(spec = {}, all = {}) =>
  //name and value is the key value pair for the
  //modules full modules object 
  ({ name, value }, cb = () => { }, modules) => {

    if (name && value) {
      console.log(`update feature set for ${featureName}`);
      let change = coerceTypes({ [name]: value }, spec);
      cb(inspector({ ...all, [featureName]: change }));
    } else if (modules) {
      console.log(`merging sub control results ${featureName}`)
      cb(inspector(modules));
    }
  }
  // handles top level stuff, this is a stub for read only
  export const changeItemInspector = (featureName = 'item', inspector = () => { }) =>
    //feature initialization with 
    //spec - hash of field names
    //all - this is the whole module for cross validation
    //cb - notifies the composition layer
    (spec = {}, all = {}) =>
      //name and value is the key value pair for the
      //modules full modules object 
      ({ name, value }, cb = () => { }, modules) => {
  
        if (name && value) {
          console.log(`update feature set for ${featureName}`);
          let change = coerceTypes({ [name]: value }, spec);
          cb(inspector({ ...all, [change]: change }));
        } else if (modules) {
          console.log(`merging sub control results ${featureName}`)
          cb(inspector(modules));
        }
      }
export default changeInspector
