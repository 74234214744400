import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { inspect } from '../../helpers/category.helper';
import System from '../../routes/systems';
import { createItem, editItem, removeItem } from '../../reducers/item.reduce';
const winona = (state) => { console.log('runagain'); return state.categories; }

const mapStateToProps = state => {
  return {
    //  items:getItemsByOwner(state.activeAccount.id, state),
    activeAccount: state.activeAccount,
    //item:state.item,
    inventory: Object.keys(state.inventories).map(i => state.inventories[i]),
    categories: winona(state),
    features: state.features,
    featureMap: inspect(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    goTo: loc => {
      // dispatch(route(loc))
    },
    newItem: item => {
      dispatch(createItem(item))
    },
    edit: item => {
      dispatch(editItem(item))
    },
    clone: (item1, item2) => {
      //  dispatch(cloneItem(item1, item2))
    },
    remove: item => {
      dispatch(removeItem(item))
    }
  }
}
const SystemView = connect(mapStateToProps, mapDispatchToProps)(System)
export const PAGE_ITEM = 'systemPage';
export default SystemView;
