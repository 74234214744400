/* this could be the EOF here */

const BackPack = (initial = {}) => {
  const struct = {
    complete: false,
    name: '',
    color: ''
  };
  return { ...struct, ...initial };
};
BackPack.ICON_STRING = 'fa-backpack';
export default BackPack;
