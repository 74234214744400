/**
   * Displaces Object
   * defines the object data parameters
   * should offer the ability to discern options for those parameters
   */

const Displaces = (initial = {}) => {
  //assume that all items displace a rectangular volume for now
  const struct = {
    complete: false,
    rigid: true,
    width: 0,
    height: 0,
    length: 0,
    unit: "in",
    displaces: 0,
    displacesUnit: "L"
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  displacesUnits: [{ id: 'L', name: 'Liters' }, { id: 'mL', name: 'Milliliters' }],
  units: [{ id: 'in', name: 'Inches' }, { id: 'cm', name: 'Centimeters' }]
};
Displaces.ICON_STRING = 'fa-flask';
export default Displaces;
