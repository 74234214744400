import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '../../components/button-group';
import { accountById } from '../../helpers/accounts.helper';
import { withRouter } from 'react-router-dom';
import AccountFactory from '../../types/account.value';
import WellFlag from '../../components/well-flag';
import MetaForm from '../../components/meta-form';
import Panel from '../../components/panel';
/**
 * AccountEdit
 * @UXFlowId: a3
 * user inputs: avatar, name, email
 * actions: handleSubmit
 */
class AccountEdit extends Component {
  constructor(props) {
    super(props)
    console.log('props.match.params.id:' + props.match.params.id);
    if (!props.match.params.id) {
      this.redirect = props.activeAccount;
    } else {
      this.redirect = accountById(props.match.params.id, props.accounts)
    }
    this.b = AccountFactory(this.redirect)
    this.state = {
      name: this.b.name,
      avatar: this.b.avatar
    }
    this.messages = {
      name: "This will show on your shared profile messages",
      comment: "unique markings"
    }
    this.buttons = [{
      type: "submit",
      className: "btn btn-default",
      onClick: (event) => {

        event.preventDefault();
        props.handleSubmit(this.state);
      },
      title: "Create",
      name: "Continue"
    }, {
      type: "button",
      className: "btn btn-secondary",
      onClick: (event) => {
        event.preventDefault();
        this.setState({ name: '', avatar: '' })
      },
      title: "Clear",
      name: "Reset"
    }];

    this.labels = {
      name: "Account Name",
    };
    this.onItemMeta = this.onItemMeta.bind(this)

  }

  /* process image, name and comment data */
  onItemMeta(event) {
    console.log('meta update ' + event.name);
    if (this.state.hasOwnProperty(event.name)) {
      this.setState({ [event.name]: event.value });
    } else if (event.name === 'image') {

      this.setState({ avatar: event.value });
    }
  }

  render() {
    if (!this.redirect) return (
      <div className='four-oh-four'>
        <WellFlag>That Account does not exist 404
        </WellFlag>
      </div>
    )
    return (
      <Panel className="account-edit panel-fill col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4">
        <form name="account-edit" onSubmit={() => false}>
          <div className="card-header" >{this.props.message}</div>
          <div className="card-body">
            <MetaForm onChange={this.onItemMeta} name={this.state.name} image={this.state.avatar} messages={this.messages} labels={this.labels} />
            <ButtonGroup values={this.buttons} />
          </div>
        </form>
      </Panel>)
  }
};

AccountEdit.propTypes = {
  match: PropTypes.any,
  message: PropTypes.string,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  activeAccount: PropTypes.any,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.any
  }))
};

AccountEdit.defaultProps = {
  message: 'Edit Account Info',
  accounts: []
};

export default withRouter(AccountEdit);
