import React from 'react';
import PropTypes from 'prop-types';

/**
 * Provides an expanding panel with css layout as defined in spec.
 */
const PanelRow = ({components, className, columnStyle }) => {
  if(!components || !components.length) return null;
  return (
      <div className={`panel-${className}`} >
        <span className={`${columnStyle}-left`}>{components[0]}</span>
        <span className={`${columnStyle}-right`}>{components[1]}</span>
      </div>
  );
};

PanelRow.propTypes = {
  columnStyle: PropTypes.string,
  className: PropTypes.string,
  components: PropTypes.array
};
PanelRow.defaultProps = {
  className: 'default',
  columnStyle: 'default'
};

export default PanelRow;