
const Potable = (initial = {}) => {
  const struct = {
    complete: false,
    safe: false,
    fluid: '',
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  fluid: [{ id: 'water', name: 'Drinkable Water', safe: true }, { id: 'grey', name: 'Potable Water (unsafe to drink)', safe: false }, { id: 'spirits', name: 'Liquor', safe: true }, { id: 'accelerant', name: 'Fuel (Alchohol, Kerosene)', safe: false }]
};
Potable.ICON_STRING = 'fa-umbrella';
export default Potable;
