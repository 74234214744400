import { connect } from 'react-redux';
import Intro from './intro';
// import { withRouter } from 'react-router-dom';
const mapStateToProps = state => {
    return {
        accounts: state.accounts
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

const IntroView = connect(mapStateToProps, mapDispatchToProps)(Intro)

export const PAGE_INTRO = 'introPage';
export default IntroView
