
import BackPack from '../types/backpack.value';
import { changeFeatureInspector } from '../utils/change-inspector';
import generateFromObject from '../utils/options';
import { getItemById, getItems } from './inventory.helper';
import { getActiveAccount } from './accounts.helper';

export const doPackSelection = (a) => {
    return {
        type: "SET_ACTIVEPACK",
        active: a.id
    };
}
/**
 * Selectors
 */
export const getActivePack = (state, packId) => {
    return {};
}
/**
 * pull the current owners packs from the state.backpacks
 * join against state.items for name and thumb
 * @param {*} state
 */
export const getPackCollection = (state) => {
    console.log(`getting pack collection`);
    if(state.backpack && state.backpack instanceof Object){
        const owner = getActiveAccount(state).id;
        const items = getItems(state);
        let list = [];
        if(owner){
            const packs = Object.values(state.backpack)
                .filter( p =>
                    (p && p.owner === owner) ? true : false);
            if(packs.length > 0){
                list = packs.map( m => {
                    return items[m.id]
                })
            }
        }
        const toArray = generateFromObject(list, 'id', 'name');

        console.log(`pack collection to option set ${toArray}`);
        if(toArray.length > 0) return toArray;
    }
    return [];
}
export const getPacks = (state) => {
    if (state.backpack && state.activeAccount && state.activeAccount.id) {
        const tgt = Object.keys(state.backpack).filter(i => state.backpack[i].owner === state.activeAccount.id)
        if (tgt.length > 0) {
            return tgt.map(g => getItemById(state, g));
        }
    }
    return [];
}

export const getPacksByOwner = (id, state) => {
    if (id && state.backpack) {
        const k = Object.values(state.backpack);
        if (k.length > 0)
            return k.filter(p => p.owner && p.owner === id)
    }
    return [];
}
/**
 * Inspector should force the calculation of any derived values
 * For backpack this would be checking its contents and weights
 * per section
 * @param {*} o
 */
export const inspect = o => {
    return { ...o, backpack: BackPack(o.backpack) };
}
// updateState will inspect an object and reports to handle backpack
export const updateState = changeFeatureInspector('backpack', inspect);

/**
 * as a feature, validation requires iterating through the module dependencies
 * and making sure they return valid
 * @param {*} o
 */
export const validate = (o = {}) => {
    return (isBackpack(o.backpack) && o.backpack.name !== '')
}

export const isBackpack = ({ name, color }) => {
    return (name && color);
}
export default {
    inspect,
    validate,
    updateState
}
