/**
 * Middleware for app data updates
 * one or more items from a user profile can be included
 * and each should be processed to ensure that the items are proper instances
 * this removes funny values being included from a ui leak and avoids having to
 * rebuild the table set by hand.
 */
import { VALIDATE_ITEMS, editItem, ASSIGN_ITEMS } from '../reducers/item.reduce';
import { UPDATE_FEATURES } from '../reducers/feature.reduce';
import { getActiveAccountItems } from '../helpers/accounts.helper';
import { setOwner } from '../helpers/item.helper';
// import { normalizeItemProfiles } from '../item.reduce';
// import { filterByOwner } from '../inventory.reduce';
// import { getOwner } from '../account.reduce';
/**
 * each page 
 * @param {*} store 
 */
const itemValidator = store => next => action => {

  switch (action.type) {
    case ASSIGN_ITEMS:
      //ideally this should assign new uuids to the items
      const owned = action.items.map( i => setOwner(action.owner, i));
      const assigned = editItem({item:owned})
      if(assigned){  
        console.log(`assign to owner: ${action.owner}`);
        return next(assigned);
      }
      break;
    case VALIDATE_ITEMS:
      // the owner of the item may not be the activeAccount so owner
      // authorization should be respected
      const s = store.getState();
      const res = getActiveAccountItems(s)
      const payload = editItem({item:res})
      if(payload){  
        console.log('synchronize');
        return next({
          ...payload,
          type: UPDATE_FEATURES,
        });
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return next(action);
  }
}
export default itemValidator
