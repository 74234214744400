//activeAccount action constants
export const PACK_ADD = 'onPackAdd';
export const PACK_EDIT = 'onPackEdit';
//these are internal actions
export const PACK_REMOVE = 'onPackRemove';

/* account state actions */ 
export const addPack = data => {
    return {
        type:PACK_ADD,
        data
    }
}
/**
 * save the activeAccount profile to its spot in accounts 
 * @param {*} account 
 */
export const editPack = data => {
  return {
    type: PACK_EDIT,
    data
  }
}
export const removePack = data => {
  return {
    type: PACK_REMOVE,
    data
  }
}