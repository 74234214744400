
const Compartments = (initial = {}) => {

  const struct = {
    //CHANGED sections will contain parition indexes instead of the object
    sections: [],
    //ui '3d' resolution
    matrix: [0, 0, 0],
    //maps sectionarray index with matrix position
    sectionMap: [
      //[0,0,0], this is literally the same as mapping sections[x].location
      //compartments needs to know it, but sections may not
    ]
  };
  return { ...struct, ...initial };
};
Compartments.ICON_STRING = 'fa-cube';
export default Compartments;
