/**
 * 
 */
import { connect } from 'react-redux';
import AccWid from './account-widget';
import { exit as clearActive } from '../../reducers/activeAccount.reduce';
import { exit as clearTrip } from '../../reducers/activeTrip.reduce';
import { getItemsByOwner } from '../../helpers/item.helper';
import { getPacksByOwner } from '../../helpers/backpack.helper';
import { getTripsByOwner } from '../../helpers/trip.helper';

const mapStateToProps = state => {
    const account = state.activeAccount;
    return {
        account,
        image: account.avatar || '',
        status: (Object.keys(account).length) ? 'online' : 'offline',
        tripCount: getTripsByOwner(account.id, state).length,
        itemCount: getItemsByOwner(account.id, state).length,
        packCount: getPacksByOwner(account.id, state).length
    };
}
const mapDispatchToProps = dispatch => {
    return {
        logout: t => {
            dispatch(clearActive())
            dispatch(clearTrip())
        }
    };

}

/**
 */
const AccountTab = connect(mapStateToProps, mapDispatchToProps)(AccWid)
export default AccountTab
