
import { crudObjectReducer, removeFromObject, editToObjectDump, editArrayToObjectDump } from '../utils/crud-reducer';
import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS, REMOVE_ITEM } from '../reducers/item.reduce';
import { UPDATE_FEATURES } from './feature.reduce';


//Selectors 
export const getEdibles = state => state.edible;
export const getEdibleById = (state, id) =>
  getEdibles(state).find(ac => ac.id === id);

const edibles = crudObjectReducer('edible', {
  [CREATE_ITEM]: editToObjectDump,
  [EDIT_ITEM]: editToObjectDump,
  [EDIT_ITEMS]: editArrayToObjectDump,
  [UPDATE_FEATURES]: editArrayToObjectDump,
  [REMOVE_ITEM]: removeFromObject,
});

export default edibles
