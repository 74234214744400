/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'
import { TextComp } from './inputs';
import ImagePreview from './image-preview';
import WellFlag from './well-flag';

/**
 * Provides the name, image and comment meta data for item, account, pack, trip
 * @param {*} props 
 */
const MetaDisplay = props => {

  const { name, comment, image, labels, messages } = props;

  const isHidden = (a) => {
    return (props[a] !== '');
  }

  return (
    <div className="meta-display">
      {isHidden('image') &&
        <ImagePreview read={true} value={image} draggable="false" />
      }
      {(labels.name) ?
        <React.Fragment>
          <WellFlag ui='danger' hide={isHidden('name')}>
            {messages.name}
          </WellFlag>
          <TextComp value={name} label={labels.name} formId='name'/>
        </React.Fragment> : null
      }

      {(labels.comment) ?
        <React.Fragment>
          <WellFlag ui='danger' hide={isHidden('comment')}>
            {messages.comment}
          </WellFlag>
          <TextComp value={comment} label={labels.comment} formId='comment' />
        </React.Fragment> : null
      }
    </div>
  )
}

MetaDisplay.propTypes = {
  onChange: PropTypes.func,
  messages: PropTypes.shape({
    name: PropTypes.any,
    comment: PropTypes.any,
    image: PropTypes.any
  }),
  labels: PropTypes.shape({
    name: PropTypes.any,
    comment: PropTypes.any,
    image: PropTypes.any
  }),
  comment: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.any
}

MetaDisplay.defaultProps = {
  messages: {
    name: '',
    comment: '',
    image: ''
  },
  labels: {
    name: '',
    comment: '',
    image: ''
  },
  onChange: () => { }
}

export default MetaDisplay