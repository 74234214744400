/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'
import { TextComp } from './inputs';
import ImagePreview from './image-preview';
import WellFlag from './well-flag';

/**
 * Provides the name, image and comment meta data for item, account, pack, trip
 * @param {*} props 
 */
const MetaForm = props => {

  const { name, comment, image, labels, messages, onChange } = props;

  const metaChange = e => {
    onChange(e.target)
  }

  const imageChange = e => {
    //either update here or parent must update funky
    onChange({ name: 'image', value: e.resizedPreview })
  }

  const showRequired = (a) => {
    return (messages[a] !== '');
  }

  const isHidden = (b) => {
    return (props[b] !== '');
  }
  return (
    <div className="meta-form">
      <WellFlag ui='danger'
        hide={isHidden('image')} >
        {messages.image}
      </WellFlag>
      {showRequired('image') &&
        <ImagePreview handleSubmit={imageChange} value={image} draggable="false" />
      }
      {(labels.name) ?
        <React.Fragment>
          <WellFlag ui='danger' hide={showRequired('name') && isHidden('name')}>
            {messages.name}
          </WellFlag>
          <TextComp value={name} label={labels.name} formId='name' onChange={metaChange} />
        </React.Fragment> : null
      }

      {(labels.comment) ?
        <React.Fragment>
          <WellFlag ui='danger' hide={showRequired('comment') && isHidden('comment')}>
            {messages.comment}
          </WellFlag>
          <TextComp value={comment} label={labels.comment} formId='comment' onChange={metaChange} />
        </React.Fragment> : null
      }
    </div>
  )
}

MetaForm.propTypes = {
  onChange: PropTypes.func,
  messages: PropTypes.shape({
    name: PropTypes.any,
    comment: PropTypes.any,
    image: PropTypes.any
  }),
  labels: PropTypes.shape({
    name: PropTypes.any,
    comment: PropTypes.any,
    image: PropTypes.any
  }),
  comment: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.any
}

MetaForm.defaultProps = {
  messages: {
    name: '',
    comment: '',
    image: ''
  },
  labels: {
    name: '',
    comment: '',
    image: ''
  },
  onChange: () => { }
}

export default MetaForm