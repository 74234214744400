/**
   * Displaces Object
   * defines the object data parameters
   * should offer the ability to discern options for those parameters
   * TODO: determine data structure, should there be a template object
   *       and a validator object to match?
   */
export const supportedUnits = { oz: true, L: true, mL: true };

export const conversionUnits = {
  //fl oz
  oz: {
    oz: 1,
    L: 0.0295735,
    mL: 29.5735
  },
  L: {
    L: 1,
    oz: 33.814,
    mL: 1000
  },
  mL: {
    mL: 1,
    L: 0.001,
    oz: 0.033814
  }
};
export const displaceConvertFromTo = (displaces, from, to) => {
  //generate a sexy conversion  
  return Number(displaces || 0) * conversionUnits[from][to];
};

export default displaceConvertFromTo;
