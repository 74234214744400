/**
 * Simplifies the generating and building of an item
 * feature property set
 */
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../components/panel';
import WellFlag from '../../components/well-flag';


export const PackListing = props => {
  return (
    <Panel className='pack-listing panel-fill panel-flex col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4'>
      <WellFlag>
        {props.mostRecent}
        Unlike the edit view for backpacks, this is the trophy area for packs (read-only but links to actions).
        Sorted by most frequently used, traveled and rated.
        [FUTURE]ladder ranking option will allow you to compare your pack experience with others with the same SKU
        [charts]
        you will see the number of trips, mileage and years logged
        using a pack in a trip allows a memory of previous configurations
        we can attempt to apply that to a current trip with similar requirements
        this is where having a trip history bound to a pack becomes useful
      </WellFlag>
    </Panel>
  )
}
PackListing.propTypes = {
  mostRecent: PropTypes.string,
  intent: PropTypes.string,
  packs: PropTypes.array
}
export default PackListing
