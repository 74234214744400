
const Portable = (initial = {}) => {
  const struct = {
    complete: false,
    status: 'danger',
    mode: "shoulder",
    carryCap: 0,
    net: 0,
    unit: "lb"
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  modes: [{ id: 'shoulder', name: 'shoulder straps only' }, { id: 'hip-shoulder', name: 'shoulder straps and hip belt' }, { id: 'external', name: 'external frame (metal)' }, { id: 'waist', name: 'fanny pack' }, { id: 'sling', name: 'messenger bag' }]
};
Portable.ICON_STRING = 'fa-suitcase';
export default Portable;
