import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import InfoCard from '../../components/info-card';

const ActiveTripWidget = ({ trip, links }) => {
  if (trip.id) {
    return (
      <React.Fragment>
        <h3>Active Trip Options</h3>
        <div className="d-flex flex-row flex-wrap" >
          <InfoCard link={links.food} header={"I ate/added Food"} title={`Update your rations`} icon={`fa-cutlery`}>
            eating a meal is great, you feel full and its less weight in your pack, keep track of the trash and your remaining portions
            if you get into trouble we can ration it.
        </InfoCard>
          <InfoCard link={links.hydrate} header={"I drank/refilled Fluids"} title={"Update your drinking fuel tank"} icon={"fa-flask"}>
            drinking not only hydrates the person. it reduces the weight of your pack and updates your travel range, low fluid levels will prompt a refill alert
          </InfoCard>
          <InfoCard link={links.outfits} header={"I am too cold/hot"} title={"Change the outfits that have been assembled for your trip"}
            icon={"fa-heartbeat"}>Not everyone likes to sleep with full thermals and some people need more than an 800 fill down sleeping bag to be comfy
          </InfoCard>
          <InfoCard link={links.sleep} header={"Am I on a Rock or a Hammock?"} title={"Change the bed layering or upgrade to the 4 season tents"} icon={"fa-bed"}>
            Look at all of the aspects to getting a good nights sleep, from padding, to suspension, to insulation loft
      </InfoCard>
          <InfoCard link={links.travel} header={"Did I carry a suitcase or a basket?"} title={"All the extra gadgets and things that aren't critical but are sure nice to have!"} icon={"fa-map"}>Not only does this cover which pack you used, but how you managed, trekking poles, gps, map, cookware
      </InfoCard>
          <InfoCard link={links.damage} header={"I broke/fixed gear"} title={"Describe how bad the damage is"} icon={"fa-chain-broken"}>update the life span logic to include an accident and perhaps suggest a replacement that will support the missing functionality
      </InfoCard>
        </div>
      </React.Fragment>
    );
  }
  return null;
}

ActiveTripWidget.propTypes = {
  links: PropTypes.object,
  trip: PropTypes.object
}

ActiveTripWidget.defaultProps = {
  trip: {},
  links: {
    trip: '/trip/create',
    item: '/item/create',
    items: '/item/all',
    food: '/activity/eat',
    hydrate: '/activity/drink',
    sleep: '/activity/sleep',
    travel: '/activity/hike',
    damage: '/activity/damage',
    rank: '/activity/rank',
    systems: '/activity/systems',
    outfits: '/activity/outfits'
  },
}
export default ActiveTripWidget;
