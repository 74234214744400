/**
   * Displaces Object
   * defines the object data parameters
   * should offer the ability to discern options for those parameters
   * TODO: determine data structure, should there be a template object
   *       and a validator object to match?
   */

const Capacity = (initial = {}) => {

  const struct = {
    complete: false,
    status: 'danger',
    volume: 0,
    net: 0,
    //volume and current volume must be the same, use displaceFilter to
    //guarantee proper conversion.
    unit: "L"
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  units: [{ id: 'L', name: 'Liters' }, { id: 'mL', name: 'Milliliters' }]
};
Capacity.ICON_STRING = 'fa-database';
export default Capacity;
