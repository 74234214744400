import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { inspect } from '../../helpers/category.helper';
import Item from '../../routes/item';
import { createItem, editItem, removeItem } from '../../reducers/item.reduce';
import { getActiveAccountItems, getActiveAccount } from '../../helpers/accounts.helper';
const winona = (state) => { console.log('runagain'); return state.categories; }

const mapStateToProps = state => {
  return {
    activeAccount: getActiveAccount(state),
    inventory: getActiveAccountItems(state),
    categories: winona(state),
    features: state.features,
    featureMap: inspect(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    goTo: loc => {
      // dispatch(route(loc))
    },
    newItem: item => {
      dispatch(createItem(item))
    },
    edit: item => {
      dispatch(editItem(item))
    },
    /*  clone:(item1, item2) => {
        dispatch(cloneItem(item1, item2))
      },*/
    remove: item => {
      dispatch(removeItem(item))
    }
  }
}
const ItemView = connect(mapStateToProps, mapDispatchToProps)(Item)
export const PAGE_ITEM = 'itemPage';
export default ItemView;
