/**
 * edit item as pack, what makes this different is the focus will
 * include overall weight calculation, trip goal achievement
 * balance based on weight distribution
 */
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../components/panel';

export const PackPartitions = props => {
  return (
    <Panel className='pack-partitions panel-fill col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4'>
    following on the need for trip history we can start to offer data points that will help solve discomfort during travel, excessive wear front a strap..etc 
      Pack Partitions; shows the effects of a balanced pack [photo of effective weight dist on shoulders]
    add ray effect to show balance. helper will use the matrix to calculate a CoG based on weight and give a degree angle with 12 o clock being zero, negative
    to left,
    option: use web gl to allow rotation of the camera and a XYZ representation of the weight balance
    </Panel>
  )
}
PackPartitions.propTypes = {
  goTo: PropTypes.func,
  intent: PropTypes.string,
  packs: PropTypes.array
}
export default PackPartitions
