
const generateFromObject = (o = {}, id_key = '', name_key = '') => {
  const keyed = Object.values(o);
  if(keyed.length > 0){
    return keyed.map( target=> {
      const id = target[id_key];
      const name = target[name_key];
      if(id && name){
        return {
          id,
          name
        };
      }
    })
  }
  return [];
}

export default generateFromObject
