import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS, REMOVE_ITEM } from '../reducers/item.reduce';
import { crudObjectReducer, addToObject, editToObject, removeFromObject, editArrayToObject } from '../utils/crud-reducer';
//Inventorys action constants
export const INVENTORY_EDIT = 'onInventorySave';
export const INVENTORY_ADD = 'onInventoryAdd';
export const INVENTORY_REMOVE = 'onInventoryDelete';

/**
 * TODO break up inventory into more slices
 * so that inventories = combineReducers({
 *  owner, --> owner plus references to item list
 *  items, --> this would be meta data
 *  lifespan, --> new! allows selectors by usage and stuff
 *  parents, --> removes this from the current feature stack state
 *  features, -> all item module data would be extracted from the item
 *               into here, allowing an easier time at collating statistics
 *               across items. 
 * })
 */
/* Inventory state actions */
export const addInventory = data => {
  return {
    type: INVENTORY_ADD,
    data
  }
}
/**
 * save the activeInventory profile to its spot in inventorys 
 * @param {*} inventory 
 */
export const editInventory = data => {
  return {
    type: INVENTORY_EDIT,
    data
  }
}
/**
 * save the activeInventory profile to its spot in inventorys 
 * @param {*} inventory 
 */
export const removeInventory = data => {
  return {
    type: INVENTORY_REMOVE,
    data
  }
}

const inventories = crudObjectReducer('item', {
  [CREATE_ITEM]: addToObject,
  [EDIT_ITEM]: editToObject,
  [EDIT_ITEMS]: editArrayToObject,
  [REMOVE_ITEM]: removeFromObject
});

export default inventories
