export const CREATE_ACTION = 'add';
export const EDIT_ACTION = 'edit';
export const REMOVE_ACTION = 'remove';

/**
 * Generic reducer transactions to handle the most common 
 * CRUD use cases, adding a removing or editing a record as defined
 * by action[stateName]
 * @param {*} state 
 * @param {*} action 
 */
export const addToArray = (state, action, stateName) => {
    if (action[stateName] && action[stateName].id) {
        //this is a simple push, there is no check for an existing record
        console.info(`setting ${action[stateName].id} to ${stateName} state `);
        return [...state, { ...action[stateName] }];
    }
    return state;
}

export const addToObject = (state, action, stateName) => {
    //todo collison detect, adding should deny
    if (action[stateName] && action[stateName].id) {
        console.info(`setting ${action[stateName].id} to ${stateName} state `);
        return { ...state, [action[stateName].id]: { ...action[stateName] } };
    }
    return state;
}

export const editToObject = (state, action, stateName) => {
    // && state[action[stateName].id] this prevents old data from being updated
    if (action[stateName] && action[stateName].id) {
        console.info(`editing ${action[stateName].id} in ${stateName} state`);
        state[action[stateName].id] = { ...action[stateName] };
    }
    return state;
}

export const editArrayToObject = (state, action, stateName) => {

    let t = { ...state };
    // eslint-disable-next-line array-callback-return
    action.data.map(i => {
        t = editToObject(t, { ...i }, stateName)
    })
    return t
}
/**
 * Object given without an id to be merged as is with the state.
 * @param {*} state 
 * @param {*} action 
 * @param {*} stateName 
 */
export const editToObjectDump = (state, action, stateName) => {
    // && state[action[stateName].id] this prevents old data from being updated
    if (action[stateName] && Object.keys(action[stateName]).length > 0) {
        console.info(`editing a dump merge of ${Object.keys(action[stateName]).length} elements in ${stateName} state`);
        //should be more careful but meah 
        return { ...state, ...action[stateName] }
        //state[action[stateName].id] = {...action[stateName]};
    }
    return state;
}

export const editArrayToObjectDump = (state, action, stateName) => {

    let t = { ...state };
    // eslint-disable-next-line array-callback-return
    action.data.map(i => {
        t = editToObjectDump(t, { ...i }, stateName)
    })
    return t
}
export const removeFromObject = (state, action, stateName) => {

    if (action[stateName] && action[stateName].id && state[action[stateName].id]) {

        console.log(`remove ${action[stateName].id} from ${stateName}`);
        delete state[action[stateName].id];
    }
    return state;
}

export const editToArray = (state, action, stateName) => {
    // this only assumes one record per transaction
    if (action[stateName] && action[stateName].id) {

        const edit = state.findIndex(a => a.id === action[stateName].id);
        if (edit > -1) {
            console.info(`editing ${action[stateName].id} in ${stateName} state`);
            //modifying input
            state[edit] = action[stateName];
            return [...state];
        }
    }
    return state;
}

export const removeFromArray = (state, action, stateName) => {
    //this only assumes one record per transaction
    //iteration of this function where you feed the resulting state
    //will suffice as well
    if (action[stateName] && action[stateName].id) {
        const remove = state.findIndex(a => a.id === action[stateName].id);
        if (remove > -1) {
            console.log(`remove ${action[stateName].id} from ${stateName}`);
            state.splice(remove, 1);
            return [...state];
        }
    }
    return state;
}
/**
 * Reducer bundle functions
 * the costants default is an example implementation that will
 * typically be overriden because you don't use a simple string like
 * 'add' unless you have only one state event?
 * @param {*} stateName 
 * @param {*} constants 
 */
export const crudArrayReducer = (stateName = 'crud', constants = {
    [CREATE_ACTION]: addToArray,
    [EDIT_ACTION]: editToArray,
    [REMOVE_ACTION]: removeFromArray
}) => (state = [], action) => {
    if (constants.hasOwnProperty(action.type)) {
        return constants[action.type](state, action, stateName);
    } else {
        return state;
    }
}

export const crudObjectReducer = (stateName = 'crud', constants = {
    [CREATE_ACTION]: addToObject,
    [EDIT_ACTION]: editToObject,
    [REMOVE_ACTION]: removeFromObject
}) => (state = {}, action) => {
    //console.log('actiontype' + action.type + ' ' + constants.hasOwnProperty(action.type));
    if (constants.hasOwnProperty(action.type)) {
        return constants[action.type](state, action, stateName);
    } else {
        return state;
    }
}
