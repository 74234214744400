/**
 * Fillable System 
 * an item that is fillable is an item that can either improve or degrade 
 * an existing system profile.
 * recently added is profile, this is to a feature as a parent id is to an item
 */
const Fillable = (initial = {}) => {
  const struct = {
    complete: false,
    status: 'danger',
    percent: 0,
    refill: 5,
    mode: "solid",
    profile: -1
  };
  return { ...struct, ...initial };
}

export const OPTIONS = {
  modes: [{ id: 'solid', name: 'Rigid (Nalgene, Bottled Watter)', avatar: 'fillable-solid' }, { id: 'soft', name: 'Soft Bottled', avatar: 'fillable-soft' }, { id: 'bladder', name: 'Bladder Type (CamelBak, Platypus)', avatar: 'fillable-bladder' }]
};
Fillable.ICON_STRING = 'fa-hourglass-end';
export default Fillable;
