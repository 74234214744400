import { getItemsByOwner } from "./item.helper";
/**
 * Accounts Helper
 */

export const accountById = (r, accounts) => {
  // eslint-disable-next-line eqeqeq
  const e = accounts.find(o => o.id == r);
  console.log('acc is' + e);
  return e

}

//Selectors 
export const getAccounts = state => state.accounts;
export const getAccountById = (state, id) =>
    state.accounts.find(ac => ac.id === id);
    
export const getActiveAccount = state => state.activeAccount;
export const getActiveAccountItems = state => {
    const a = state.activeAccount
    if(a.hasOwnProperty('id')){
      return getItemsByOwner(a.id, state);
    }
    return [];
}