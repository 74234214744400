/**
 * Forecast

export const tripById = (trip = '', list = {}) => {
  return list[trip];
}
*/
export const parseGeoLink = (data = {}) => {
  return {
    [`${data.properties.gridX},${data.properties.gridY}`]: {
      coords: data.geometry.coordinates,
      location: data.properties.relativeLocation.properties,
      id: data.id
    }
  };
}
/**
 * convert data into a series of forecasts ordered by UTC value
 * depending on the time of day a query is made there can be up to to 2 keys 
 * with the same calendar date. (Hi/Lo forecast)
 * an hourly forecast request will increase this
 * 
 * @param {*} data 
 * @param {*} bounds 
 */

export const parseWeatherGovForecast = (data = { periods: [] }, bounds = '') => {
  const mapForecast = parseForecastPeriods(data.periods, {});
  return {
    [bounds]: {
      elevation: data.elevation || {},
      ...mapForecast
    }
  };
};

export const parseForecastPeriods = (periods = [], join = {}) => (
  periods.reduce((t, f) => ({
    ...t,
    [f.startTime]: { ...f }
  }
  ), join)
);
/**
 * weatherByDate (return hi/lo or hourly)
 * @param {String or Array} ref contains one or more category indexes 
 * @param {*} trip store
 */
export const getWeatherByDate = (ref = '', store = {}) => {
  if (ref === '') return store;
  let keys = ref;
  if (ref instanceof Array) {
    keys = ref.join();
  }
  return Object.keys(store).filter((o) => String(keys).includes(Date(o.validTimes)));
}

export const submitGeoToForecast = ( geo, cb = () => {}) => {
  if (geo && geo.coords && geo.coords.length === 2) {
    cb({
      id: geo.coords.toString(),
      coords: geo.coords
    });
  }
}
/* list will be used for damage, consumable, ratings */
export const itemList = () => {
}
/* environment, gps, length,  */
export const tripReport = () => {
}

/* activity level, duration, metrics */
export const activityReport = () => {
}

/* damage reduces item life factor, and can disable an inventory item */
export const damageReport = () => {
}
/* when stuff gets eaten, weight drops, calories get consumed, */
export const consumableReport = () => {
}
