
import filterWeight from '../helpers/weighs.filter';
import { MassOut } from '../types/units';
import Weighs from '../types/weighs.value';
import { changeInspector } from '../utils/change-inspector';

/**
* Inspector should force the calculation of any derived values
* For weighs this is the gross weight metric
*/
export const inspect = o => {
  return Weighs({ ...o, gross: gross(o) });
}

// updateState will inspect an object and reports to handle weighs
export const updateState = changeInspector('weighs', inspect);

export const validate = (o = {}) => {
  return (isWeighs(o) && o.tare > 0)
}

export const isWeighs = ({ gross, net, tare, unit }) => {
  return (unit && net >= 0 && tare >= 0);
};

export const display = o => {
  let r = MassOut();
  if (isWeighs(o)) {
    r.unit = o.unit;
    //should use the weighsFilter to ensure proper conversion
    r.value = gross(o);
  }
  return r;
};

/**
 * update net and gross weights
 * @param tgt: weighs
 * @param val:UnitObject
 */
export const net = (val, tgt) => {

  if (isWeighs(tgt) && val.value >= 0) {
    let o = { ...tgt, net: filterWeight(val.value, val.unit, tgt.unit) };
    return { ...o, gross: gross(o) };
  }
  return tgt;
};

//ASSUMES units of measure are the same
export const gross = (o = {}) => {
  if (o && o.net >= 0 && o.tare >= 0) return (o.net || 0) + o.tare;
  return o.tare || 0;
};

export default {
  inspect,
  validate,
  updateState,
  isWeighs,
  net,
  gross
}
