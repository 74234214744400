
import { crudObjectReducer, removeFromObject, editToObjectDump, editArrayToObjectDump } from '../utils/crud-reducer';
import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS, REMOVE_ITEM } from '../reducers/item.reduce';
import { UPDATE_FEATURES } from './feature.reduce';

//Selectors 
export const getShelters = state => state.shelter;
export const getShelterById = (state, id) =>
  getShelters(state).find(sh => sh.id === id);

const shelters = crudObjectReducer('shelter', {
  [CREATE_ITEM]: editToObjectDump,
  [EDIT_ITEM]: editToObjectDump,
  [EDIT_ITEMS]: editArrayToObjectDump,
  [UPDATE_FEATURES]: editArrayToObjectDump,
  [REMOVE_ITEM]: removeFromObject,
});

export default shelters
