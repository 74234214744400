import React from 'react';
import PropTypes from 'prop-types';

/**
 * Hideable content area with a bootstrap alert coloring index
 * @param hide:Boolean returns null, unmounts from DOM
 * @param ui:String|'info'|'success'|'warning'|'error' color of background
 *
 */
const WellFlag = ({ children, hide, ui }) => {
  return ((!hide) ?
    <div className={`m-1 alert alert-${ui}`}>
      {children}
    </div> : null);
};

WellFlag.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.any,
  ui: PropTypes.string
};

WellFlag.defaultProps = {
  hide: false,
  ui: "info"
};
export default WellFlag
