/**
 * Pack
 *  view
 *    listing of packs
 *    pack info panel
 *    pack balance and partition location
 *    link out to container view
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Panel from '../components/panel';
import { Route, Switch } from 'react-router-dom';
import PackListing from '../containers/Pack/pack-listing';
import PackInfo from '../containers/Pack/pack-info';
import PackPartitions from '../containers/Pack/pack-partitions';
import { getActivePack, getPackCollection, doPackSelection, getPacks } from '../helpers/backpack.helper';
import { mostRecentlyUsed } from '../helpers/trip.helper';
import { getUnclaimedItems } from '../helpers/item.helper';
import { getActiveManifest, getPreviousManifests } from '../helpers/manifest.helper';

/**
 * Home View does not have different states as components
 * but it can have different layout schemes,
 * do we do this as a configuration or as component template?
 */

export const CREATE_PACK = 'createPackProfile';
export const EDIT_PACK = 'editPackProfile';
export const PACK_SLICES = 'packPartitions';
export const PACK_INFO = 'packInfo';
export const PACK_LIST = 'showPacks';
export const SELECT_PACK = 'selectPackProfile';

const PackRoutes = props => {
  const { match } = props;
  /**
      goTo(CONTENT_EDIT);
      return (<div/>);
  }*/
  return (
    <Switch>
      <Route path={`${match.path}/list/:id`} render={() => <PackListing {...props} />} />
      <Route path={`${match.path}/info/:id`} render={() => <PackInfo {...props} />} />
      <Route path={`${match.path}/slices/:id`} render={() => <PackPartitions {...props} />} />
      <Route render={() => <PackListing {...props} />} />
    </Switch>
  );
}
PackRoutes.propTypes = {
  match: PropTypes.object,
  goTo: PropTypes.func,
  intent: PropTypes.string,
  //item:PropTypes.object
};

/**
 * packs, selectedPack,
 * @param {*} state
 */
const mapStateToProps = (state, ownProps) => {
  return {
    // selectedPack and mostRecent can be equal
    // selectedPack
    selectedPack: getActivePack(state, ownProps.match.params.id),
    mostRecent: mostRecentlyUsed(state),
    packs: getPacks(state),
    freeItems: getUnclaimedItems(state),
    // selectedPack may not be linked  to selectedPack
    activeManifest: getActiveManifest(state, ownProps.match.params.id),
    manifestHistory: getPreviousManifests(state, ownProps.match.params.id)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectPack: (i) => {
      dispatch(doPackSelection(i))
    },
    applyManifest: (pack, manifest) => {
      dispatch({ type: 'SET_MANIFEST_TO_PACK', pack, manifest });
    },
    updateManifest: (manifest, diff) => {
      dispatch({ type: 'UPDATE_MANIFEST', diff, manifest });
    },
    setActiveManifest: (manifest) => {
      dispatch({type:'SET_MANIFEST_ACTIVE', manifest});
    },
  }
}
const PackView = connect(mapStateToProps, mapDispatchToProps)(PackRoutes)

export default PackView
