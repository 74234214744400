
import { TRIP_ADD, TRIP_SAVE } from './trips.reduce';

//activeTrip
export const TRIP_CLEAR = 'clearActiveTrip';
export const TRIP_ACTIVE = 'setActiveTrip';
//Actions

/* thunk examples */
/**
 * to connect, the existing activeTrip is cleared
 * before the new account is marked
 */
export const connect = activeTrip => dispatch => {

  dispatch({ type: TRIP_ACTIVE, activeTrip });
}
/**
 *  when clearing the active account, any changes
 *  made to the profile that is not in sync with the trip[]
 *  will be saved off for clearing
 */
export const exit = trips => (dispatch, getState) => {
  dispatch({ type: TRIP_SAVE, trip: getState().activeTrip });
  dispatch({ type: TRIP_CLEAR });
}

//Reducer
const activeTrip = (state = {}, action) => {
  switch (action.type) {
    case TRIP_ADD:
      console.log('do i have active trip?');
      if (!action.activeTrip) return state;
    // eslint-disable-next-line no-fallthrough
    case TRIP_ACTIVE:
      console.info(`setting ${action.activeTrip.id} to the activeTrip slot`);
      return action.activeTrip;
    case TRIP_CLEAR:
      //info
      console.info(`removing ${state.id} from the activeTrip slot`);
      return {};
    default:
      return state;
  }
}

export default activeTrip
