/* eslint-disable array-callback-return */
import Features from '../types/features';
import Controls from '../types/controls';

/**
 * Generate a module object with default values by category 
 * @param {*} val 
 * @param {*} categorySet 
 */
export const generate = (val, categorySet = []) => {
  let modules = {};
  let features = [];
  let t = '';
  if (val) {
    t = val;
    const cat = categorySet.find(o => o.id === val);
    if (cat) {

      cat.controls.map(o => {
        if (Controls[o]) modules[o] = Controls[o]();
      });
      cat.features.map(o => {
        if (Features[o]) modules[o] = Features[o]();
      });

      features = [...cat.features]
    }
  }
  return { category: t, modules, features };
}
/**
 * this would be less ugly with  a schema
 * this is effectively feature.value
 * generates a named object of the first feature reference 
 * and a child key of the item's id
 */
export const featureIndex = ({ id, parent, owner, features }) => {
  if (Array.isArray(features) && features.length > 0) {
    return {
      //this probably should be a name value pair but will leave as is and assume
      //that the update crud reducer with indexing will sort it.
      [features[0]]: {
        [id]: { id, parent, owner }
      }
    }
  }
  return {};
}
/**
 * return an array of items paired with thier feature object
 * @param {*} a 
 */
export const multiFeatureIndex = (a = []) => {
  return a.map(i => { return { item: i, ...featureIndex(i) } });
}

export const inspect = o => {
  let q = {};
  Object.keys(Features).map(f => { q[f] = o[f] || {} })
  return q;
}
/**
 * DOn't know if i would call this tally, it running a function against a specific node
 * 
 */
export const inspectFeatureMap = (m = {}, f = '', fu = o => { }) => {
  if (m.hasOwnProperty(f)) {
    return fu(m[f]);
  }
  return {}
}
/**
 * UI Icons
 * Assemble a list of icon string names representing available 
 * features and controls for a category
 * @param {*} val 
 * @param {*} categorySet 
 */
export const categoryFeatures = (val, categorySet = []) => {
  let icons = [];
  if (val) {
    const cat = categorySet.find(o => o.id === val);
    if (cat) {
      //cat.features.map(o=> {
      //    if(Features[o]) icons.push(Features[o].ICON_STRING);
      //   });
      cat.controls.map(o => {
        //for now we do it together but later there needs to be a distinction
        if (Controls[o]) icons.push(Controls[o].ICON_STRING);
      });
    }
  }
  return icons;
}
