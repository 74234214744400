
import { crudObjectReducer, removeFromObject, editToObjectDump, editArrayToObjectDump } from '../utils/crud-reducer';
import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS, REMOVE_ITEM } from '../reducers/item.reduce';
import { UPDATE_FEATURES } from './feature.reduce';
/**
 * ACCOUNT_ACTIVE sets
 * ACCOUNT_CLEAR logs out the user 
 * //sending an account as argument will attempt to connect that user
 * @param {} state 
 * @param {*} action 
 */

//Selectors 
export const getCompartments = state => state.compartment;
export const geCompartmentById = (state, id) =>
  getCompartments(state).find(ac => ac.id === id);

const compartments = crudObjectReducer('compartment', {
  [CREATE_ITEM]: editToObjectDump,
  [EDIT_ITEM]: editToObjectDump,
  [EDIT_ITEMS]: editArrayToObjectDump,
  [UPDATE_FEATURES]: editArrayToObjectDump,
  [REMOVE_ITEM]: removeFromObject,
});

export default compartments
