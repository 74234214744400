import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import AccountView from '../containers/Account/account-view';
import AboutView from '../containers/About/about-view';
import IntroView from '../containers/Intro/intro-view';
import ItemView from '../containers/Item/item-view';
import PackView from './pack';
import HomeView from '../containers/Home/home-view';
import TripView from '../containers/Trip/trip-view';
import SystemView from '../containers/Systems/systems-view';
import DebugView from '../containers/Debug/debug-view';
// import Toast from '../components/toast';
/**
 * 
 * @param {*} props 
 */
const App = () => {

  return (
    <Router>
      <Switch>
        <Route path="/pack" component={PackView} />
        <Route path="/debug" component={DebugView} />
        <Route path="/about" component={AboutView} />
        <Route path="/home" component={HomeView} />
        <Route path="/account" component={AccountView} />
        <Route path="/item" component={ItemView} />
        <Route path="/trip" component={TripView} />
        <Route path="/activity" component={SystemView} />
        <Route component={IntroView} />
      </Switch>
    </Router>)
};

App.propTypes = {
};

App.defaultProps = {
};
/*
const mapStateToProps = state => {
  return {
      page:state.page
  };
}
const AppView = connect(mapStateToProps)(App)*/
export default App
