import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import Travel from '../containers/Systems/travel';
import Sleep from '../containers/Systems/sleep';
import Food from '../containers/Systems/food';
import Hydrate from '../containers/Systems/hydrate';
import Lifespan from '../containers/Systems/lifespan';
import Priority from '../containers/Systems/priority';
import Manage from '../containers/Systems/manage';
import Outfits from '../containers/Systems/outfits';

export const EDIT_ITEM = 'editItemView';
export const CREATE_ITEM = 'addItemView';
export const SCAN_ITEM = 'scanItemView';
export const PROFILE_ITEM = 'peekItemView';

const SystemRoutes = props => {
  const { match, categories, features, featureMap, newItem, activeAccount, inventory, edit, clone, remove } = props;

  const createParams = {
    activeAccount,
    categories,
    inventory,
    features,
    newItem,
    featureMap
  };
  const editParams = {
    ...createParams,
    edit,
    clone,
    remove
  };

  if (!activeAccount || Object.keys(activeAccount).length === 0) {
    return (<Redirect to='/account' />)
  }
  return (
    <Switch>
    <Route path={`${match.path}/eat`} render={() => <Food {...editParams} />} />
    <Route path={`${match.path}/sleep`} render={() => <Sleep {...editParams} />} />
    <Route path={`${match.path}/hike`} render={() => <Travel {...editParams} />} />
    <Route path={`${match.path}/outfits`} render={() => <Outfits {...editParams} />} />
    <Route path={`${match.path}/drink`} render={() => <Hydrate {...createParams} />} />
    <Route path={`${match.path}/damage`} render={() => <Lifespan {...createParams} />} />
    <Route path={`${match.path}/rank`} render={() => <Priority {...editParams} />} />
    <Route path={`${match.path}/systems`} render={() => <Manage {...editParams} editMode={true} />} />
    </Switch>
  );
}

SystemRoutes.propTypes = {
  featureMap: PropTypes.object,
  remove: PropTypes.func,
  match: PropTypes.object,
  edit: PropTypes.func,
  clone: PropTypes.func,
  newItem: PropTypes.func,
  // view: PropTypes.string,
  activeAccount: PropTypes.object,
  inventory: PropTypes.array,
  categories: PropTypes.array,
  features: PropTypes.array
}

SystemRoutes.defaultProps = {
  //view:'',
  features: [],
  categories: []
};
export default SystemRoutes;
