import { crudObjectReducer, removeFromObject, addToObject, editToObject } from '../utils/crud-reducer';
import { generate } from '../utils/uuid';

//Constants
export const TRIP_ADD = "createNewTrip";
export const TRIP_SAVE = "editSingleTrip";
export const TRIP_REMOVE = "deleteTrip";

//Actions
export const createTrip = data => (dispatch, getState) => {
  const t = generate(data)
  let action = {
    type: TRIP_ADD,
    trip: t
  }
  const ac = getState().activeTrip;
  // TODO use helper to check
  if (ac && Object.keys(ac).length < 3) {
    console.log('adding activeTrip to the payload');
    action = { ...action, activeTrip: t };
  }
  dispatch(action);
}
/**
 * editing a trip at this time does not have other objects other than itself
 * to worry about
 * @param {*} trip 
 */
export const editTrip = trip => {
  return {
    type: TRIP_SAVE,
    trip
  }
}
export const cloneTrip = (trip1, trip2) => {
}
export const removeTrip = action => {
}

//Reducer
const trips = crudObjectReducer('trip', {
  [TRIP_ADD]: addToObject,
  [TRIP_SAVE]: editToObject,
  [TRIP_REMOVE]: removeFromObject,
});

export default trips
