
/**
 * Edible is a feature root for food items
 * right now we dump some nute value but i think this may be on a more
 * technical side, like rehydration and requirements?
 * ...maybe make that an inline <Hydrate />
 * @param {*} initial 
 */

const Edible = (initial = {}) => {
  const struct = {
    complete: false,
    menu: -1,
    calories: 0,//its own unit
    protein: 0,//g
    fat: 0,//g
    carbs: 0//g
  };
  return { ...struct, ...initial };
};

Edible.ICON_STRING = 'fa-apple';
export default Edible;
