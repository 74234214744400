/*<!-- readonly template for viewing an item as part of a list group
@see media.html for the media verson of item
<item-element></item-element>
    <i className={`fa ${ico}` repeat="ico in $ctrl.states" title="need dynamic title"></i>
-->
*/

import React from 'react';
import PropTypes from 'prop-types';

const ItemElement = ({ children, name, thumb, comment, id, onEdit }) => {
  const _editMode = () => {
    onEdit(id)
  }
  return (
    <div className="item-element col-3 col-sm-2 m-0 p-0" onClick={_editMode} name="item-element">
      <div className="card m-1 p-0">
        <img src={thumb} alt={thumb} title={comment} className="card-img" draggable="false" />
        <div className="">
          {children || null}
        </div>
      </div>
    </div>
  )
}

ItemElement.propTypes = {
  children: PropTypes.node,
  onEdit: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  thumb: PropTypes.string,
  comment: PropTypes.string
};

ItemElement.defaultProps = {
  id: -1,
  onEdit: () => false,
  name: '',
  comment: '',
  thumb: ''
};
export default ItemElement;
