import { generate } from "../utils/uuid";
import { featureIndex, multiFeatureIndex } from "../helpers/category.helper";

export const CREATE_ITEM = 'createItemAction';
export const EDIT_ITEM = 'editItemAction';
export const EDIT_ITEMS = 'editMultipleItemAction';
export const REMOVE_ITEM = 'deleteItemAction';
export const VALIDATE_ITEMS  = 'validateItemProfiles';
export const ASSIGN_ITEMS  = 'assignItemProfiles';

/* multiple reducers listen for this */ 

/**
 * take an item profile and split it into an add request
 * the goal i guess is to keep the tree as flat as possible
 * so at this point that means dumping feature props by feature
 * instead of a group
 * so that you have something like
 * item:{name, comment, category, features:{ displaces, weighs}} 
 * becomes 
 * item:{name, comment, owner, created, parent, thumb}
 * category:{item, category}
 * hmm..notice that it looks like you have to commit an id first 
 * before being able to do anything else so this looks like the right 
 * place to format an action...duh
 * @param {*} data 
 */
export const createItem = data => {
    const t = generate(data)
    //since we have a lot of dummy cruds
    return {
        type:CREATE_ITEM,
        item:t,
        ...featureIndex(t) //adds the proper feature tag for the reducer to catch
    }
}

/**
 * editItem can invovle modifying inventory
 * be aware that editItem looks to item being an object of data, it does not
 * treat data as the definition, this is not like createItem
 * @param {*} data 
 */
export const editItem = data => {
    let t = {
        type:EDIT_ITEM,
        ...data,
        //item:{},
        ...featureIndex(data.item)
    };
    if(Array.isArray(data.item)){
        console.log('switching to multi edit items')
        t = {
            type:EDIT_ITEMS, 
            data:multiFeatureIndex(data.item)
        };
    }
    return t;
}
/* multiple reducers listen for this */ 
export const removeItem = data => {
    return {
        type:REMOVE_ITEM,
        ...data,
        ...featureIndex(data.item)
    }
}

export const validateItems = data => {
    return {
        type: VALIDATE_ITEMS,
        ...data
    };
}
// expect to have an items and owner payload
export const assignItems = data => {
    return {
        type: ASSIGN_ITEMS,
        ...data
    };
}
