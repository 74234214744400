/** 
Show fuzzy clouds and welcome panel 
this view is linked with setting appData for the first time
    <!-- ranger monkey sits here with his cool hat in a circle knockout --> 
     <!-- welcome to packit blah blah -->
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Panel from '../../components/panel';
/*         <video playsInline autoPlay muted loop playback="0.5" poster="placeholder.jpg" id="bgvideo" width="x" height="y">
          <source src="herohd1.mp4" type="video/mp4" />
        </video> */
export const Intro = props => (
  <div className="intro jumbotron jumbotron-fluid">
  <main className="masthead" role="main">
  <div className="container">
      <h1 className="display-4">PackIt!</h1>
      <p className="lead">Be the smart hopper and gear up the right way!</p>
      <hr className="my-4" />
      <p className="lead">PackIt is a web application for planning backpacking trips with optimal resource allocation and post-event analysis.</p>
      <div>
      <Link to={`/home`} className="btn btn-primary">
      Get Packing
      </Link><Link to={`/about`} className="mx-2 btn btn-secondary">
      Learn More
      </Link></div>
  </div>
  </main>
  </div>);

Intro.propTypes = {
  message: PropTypes.string,
  continue: PropTypes.func,
  logo: PropTypes.string
};

Intro.defaultProps = {
  message: `You have entered a land of organization and banana waving!`
};

export default Intro
