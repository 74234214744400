import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { SelectComp } from '../../components/inputs';
import WellFlag from '../../components/well-flag';
import { accountById } from '../../helpers/accounts.helper';
import AccountFactory from '../../types/account.value';
import Panel from '../../components/panel';
import MetaDisplay from '../../components/meta-display';

/**
 * AccountSelect
 * @UXFlowId: a2
 * user inputs: account
 * actions: handleSubmit
 * transition: history.back
 */
class AccountSelect extends Component {
	//	const {account, accounts, history, message,  handleSubmit} = props;
	//let selected = account;
	constructor(props) {
		super(props);
		this.state = {
			account: props.activeAccount,
			index: props.activeAccount.id || undefined
		}
	}

	_update = event => {
		let t = accountById(event.target.value, this.props.accounts);
		if (t) this.setState({ account: t, index: event.target.value });
	};

	/**
	 * TODO: best practice? rely on a success response and redirect at render
	 * or keep the current action of hitting back regardless of result?
	 */
	_butt = () => {
		this.props.handleSubmit(this.state.account.id);
		//history.goBack();
		this.props.history.push('/home');
	}

	render() {
		if (this.props.accounts && this.props.accounts.length === 0) {
			return <Redirect to='/account/create' />
		}
		const acc = Boolean(this.state.account.id);
		const a = acc ? this.state.account : AccountFactory();
		return (
			<Panel className="account-select panel-fill col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4">
				<WellFlag>
					{this.props.message}
				</WellFlag>
				<MetaDisplay name={a.name} image={a.avatar} />
				<SelectComp className='custom-select' label="Account" formId='account' value={this.state.index} onChange={this._update} options={this.props.accounts} />
				<div className="d-flex justify-content-between p-3">
					<button onClick={this._butt} disabled={!acc} className="btn btn-primary" title="Continue" tooltip="Continue as {account.name}">
						Continue
				</button>
				</div>
			</Panel>
		)
	}
};

AccountSelect.propTypes = {
	accounts: PropTypes.array,
	activeAccount: PropTypes.any,
	handleSubmit: PropTypes.func,
	history: PropTypes.object,
	message: PropTypes.string
};

AccountSelect.defaultProps = {
	accounts: [],
	message: 'Select the profile you wish to use'
};
export default AccountSelect;
