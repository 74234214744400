import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const InfoCard = ({ link, icon, title, header, children }) => {
  return (
    <div className="card card-info col-12 col-lg-6 mx-0" >
      <Link to={link} className="button btn btn-info" title={title}>
        <i className={`fa ${icon}`}></i>{' '}{header}
      </Link> <small><em>
        {children || null}
      </em></small></div>
  )
}
InfoCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node
}
export default InfoCard;
