import React, {Component} from 'react'
import PropTypes from 'prop-types'
import resize from '../utils/image-resize'
/**
 * ImagePreview 
 * provides an image area 
 * <action> with a button prompt user selects a file to be read
 * and converted into base64 image data
 * <option> resize to max pixel dimensions
 * <option> starting image (represents an incoming state value field
 */
class ImagePreview extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            file:'',
            imagePreview:'',
            resizedPreview:''
        };
    }

    _handleSubmit(e){
        e.preventDefault();
    }

    _handleImageChange(e) {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
          const cb = resized => {
            this.setState({
                file: file,
                imagePreview: reader.result,
                resizedPreview: resized
            });
            this.props.handleSubmit(this.state);
            }
        
          resize(reader.result, cb, this.props.resize.width, this.props.resize.height)
          
        }
    
        reader.readAsDataURL(file)
      }
    
      render() {
        let {resizedPreview} = this.state;
        const p = this.props.value ? '' : this.props.placeholder;
        let $imagePreview = null;
        if (resizedPreview) {
          $imagePreview = (<img src={resizedPreview} alt="sexy" className="card-img"/>);
        } else if(this.props.value){
          $imagePreview = (<img src={this.props.value} alt="notsexy" className='img-fluid'/>);
        }
    
        return (
          <div className="alert m-auto">
          { this.props.read === false ?
            <label htmlFor="item.file" className="position-absolute">
            <span tabIndex="1" className="btn badge badge-primary">
              <i className="fa fa-fw fa-file-image-o"></i>
              Update Image
            </span>
            </label> : null
          }
          <div className={`text-center ${p}`}>
          {$imagePreview}
          </div>
          <input id="item.file" className="fileInput" accept="image/*" type="file" 
                onChange={(e)=>this._handleImageChange(e)} style={{display:'none'}}/>
          </div>
        )
      }
    }

ImagePreview.propTypes = {
  read: PropTypes.bool,
  handleSubmit: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  resize:PropTypes.shape({
    width:PropTypes.number,
    height:PropTypes.number
  })
}

ImagePreview.defaultProps = {
  resize:{
    width:250,
    height:250
  },
  read: false,
  placeholder:'blank-image'
}

export default ImagePreview