import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '../../components/button-group';
import MetaForm from '../../components/meta-form';
import AccountData from '../../types/account.value';
import Panel from '../../components/panel';
/**
 * AccountCreate
 * @UXFlowId: a1
 * user inputs: avatar, name, email
 * actions: newAccount
 */
class AccountCreate extends Component {
  constructor(props) {
    super(props)
    this.state = AccountData({});
    this.onAccountMeta = this.onAccountMeta.bind(this)
    // eslint-disable-next-line no-sparse-arrays
    this.buttons = [{
      type: "submit",
      className: "btn btn-primary",
      onClick: (event) => {
        event.preventDefault();
        props.newAccount(this.state);
      },
      title: "Create",
      name: "Continue"
    }];

    this.labels = {
      name: "Account Name"
    }
    this.messages = {
      image: "Upload or Snap an Avatar",
      name: "How do you wish PackIt to address you?",
    }
  }

  onAccountMeta(event) {
    console.log('meta update ' + event.name);
    if (this.state.hasOwnProperty(event.name)) {
      this.setState({ [event.name]: event.value });
    } else if (event.name === 'image') {
      this.setState({ avatar: event.value });
    }
  }

  render() {
    return (
      <Panel className="account-create panel-fill col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4">
        <form name="account-create" onSubmit={() => false}>
          <div className="card-header" >
            {this.props.message}
          </div>
          <div className="card-body">
            <MetaForm onChange={this.onAccountMeta} messages={this.messages} name={this.state.name} image={this.state.avatar} labels={this.labels} />
            <ButtonGroup values={this.buttons} />
          </div>
        </form>
      </Panel>)
  }
};

AccountCreate.propTypes = {
  message: PropTypes.string,
  newAccount: PropTypes.func.isRequired,
  account: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string
  })
};

AccountCreate.defaultProps = {
  message: 'Creating an account links your posessions and allows you to return to your inventory',
  account: {
    name: '',
    avatar: ''
  }
};
export default AccountCreate;
