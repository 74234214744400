

/**
 * To better describe, wearable is something that can be worn
 * going to stick with the original plan that this is focused on clothing
 * with wearable being the master value for 
 */

const Wearable = (initial = {}) => {
  const struct = {
    complete: false,
    outfit: -1,
    layer: "",
    size: "",
    color: ""
  };
  return { ...struct, ...initial };
}

export const OPTIONS = {
  layer: [{ id: 'shirt', name: 'shirt' }, { id: 'hat', name: 'hat' }, { id: 'gloves', name: 'gloves' }, { id: 'pants', name: 'pants' }, { id: 'socks', name: 'socks' }, { id: 'shoes', name: 'shoes' }, { id: 'jacket', name: 'jacket' }, { id: 'skirt', name: 'skirt' }, { id: 'face', name: 'facial shade/mask' }],
  size: [{ id: 'u', name: 'One Size' }, { id: 'reg', name: 'Regular' }, { id: 'xl', name: 'Xtra Large' }, { id: 's', name: 'Small' }, { id: 'm', name: 'Torso' }, { id: 'l', name: 'Large' }]
};
Wearable.ICON_STRING = 'fa-male';
export default Wearable;
