/**
 * Add a checkbox intercept to an input item
 */
import React from 'react';
import PropTypes from 'prop-types';

const ToggleGenerator = (Component) => {
  const ComposedToggle = (props) => {
    const doToggle = (event) => {
      console.log('wammmo');
    }
    if (props.complete === false && props.message) {
      return (<div>{props.message}<button name="toggle" onClick={doToggle}> <i className="fa fa-check" /></button></div>);
    }
    //initial state is probably to be resolved here
    //would there be cases where even the dialog would be dynamic?
    return (<Component {...props} />);
  };
  ComposedToggle.propTypes = {
    complete: PropTypes.bool,
    message: PropTypes.string,
  };
  return ComposedToggle;
}
export default ToggleGenerator
