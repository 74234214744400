import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import InfoCard from '../../components/info-card';

const HomeLinks = ({trip, links}) => {
  if (!trip.id) {
  return (
    <React.Fragment>
      <h3>No Active Trip</h3>
      <div className="d-flex flex-row align-items-start flex-wrap" >
        <div className="card card-info col-12 col-lg-6 mx-0" >
          <h3>Ready To Begin?</h3>
          <p className="lead">You can add a new Trip without having any items and you can also tell PackIt about your previous outings to get a better idea of your travel preferences</p>
          <Link to={links.trip} className="button btn btn-info" title="Start your adventure with a Trip">
            <i className="fa fa-plus-circle"></i> Start A Trip
              </Link>
          <hr />
          <p className="lead"> BUT, Addings items is also important! <small>It will take a while to load everything if you have been collecting all sorts of travel knick nacks (no! not you) <b>The plus side is PackIt </b> will tell you what is worth keeping and whats worth a garage sale.</small></p>
          <InfoCard link={links.item} title={"Add Items"} header={"Add Items"}></InfoCard>
          <hr />
          <p> Once you have a few items in your kit, it gets easier as you can tell PackIt to clone another item and change the differences </p>
        </div>
        <InfoCard link={links.rank} title={"Rank your items for better Trips"} icon={"fa-star"} header={"I Hiked and Lived.. Or Did I?"}>

        <h3>Rate Previous Trip</h3>
        By telling PackIt! what worked and didnt during the outing, it can adjust the profile to suit your needs better
        </InfoCard>
        <InfoCard link={links.systems} title="Manage Systems" icon={"fa-bars"} header={"Am I Prepared?"} >
        <h3>Survival Overview</h3>
        You can look at your inventory by trip system, wearable is most impacted by this as your items are shown as outfits
        </InfoCard>
        </div>
    </React.Fragment>
  );
  }
  return null;
}

HomeLinks.propTypes = {
  trip:PropTypes.object,
  links:PropTypes.object,
}

HomeLinks.defaultProps = {
  trip:{},
  links: {
    trip: '/trip/create',
    item: '/item/create',
    items: '/item/all',
    food: '/activity/eat',
    hydrate: '/activity/drink',
    sleep: '/activity/sleep',
    travel: '/activity/hike',
    damage: '/activity/damage',
    rank: '/activity/rank',
    systems: '/activity/systems',
    outfits: '/activity/outfits'
  },
}
export default HomeLinks;
