import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * ButtonGroup is a container of generated buttons
 * @param {values:array} props
 * values contains attributes to be applied onto a button template 
 */
const ButtonGroup = (props) => {
    const { values } = props;
    const _buttons = () => {
        if(values.length > 0){
            return values.map( b => (<button key={`btngrp-${b.title}`} {...b}>{b.name}</button> ))
        }else{
            return null;
        }
    }

    return (
        <div className="d-flex justify-content-between py-3">
        { _buttons() }
        </div>
    );
}
/**
 * ButtonGroup is a container of generated buttons
 * @param {values:array} props
 * values contains attributes to be applied onto a button template 
 */
export const LinkGroup = (props) => {
    const {values} = props;
    const _buttons = () => {
        if(values.length > 0){
            return values.map( b => (<Link key={`btngrp-${b.title}`} {...b}>{b.name}</Link> ))
        }else{
            return null;
        }
    }

    return (
        <div className="link-group">
        { _buttons() }
        </div>
    );
}

LinkGroup.propTypes = {
    values:PropTypes.arrayOf(
        //@see ButtonTemplate
        PropTypes.shape({
            to:PropTypes.string.isRequired,
            className:PropTypes.string,
            name:PropTypes.string,
            label:PropTypes.string,
            type:PropTypes.string,
            title:PropTypes.string
    }))
};
ButtonGroup.propTypes = {
    custom:PropTypes.node,
    values:PropTypes.arrayOf(
        //@see ButtonTemplate
        PropTypes.shape({
            onClick:PropTypes.func,
            className:PropTypes.string,
            name:PropTypes.string,
            label:PropTypes.string,
            type:PropTypes.string,
            title:PropTypes.string
    }))
};

ButtonGroup.defaultProps = {
    values:[]
};
export default ButtonGroup