
const Bedding = (initial = {}) => {
  const struct = {
    complete: false,
    layer: "",
    size: "",
    color: ""
  };
  return { ...struct, ...initial };
};

export const OPTIONS = {
  layer: [{ id: 'bag', name: 'sleeping bag' }, { id: 'pad', name: 'mattress' }, { id: 'cover', name: 'blanket' }, { id: 'pillow', name: 'pillow' }, { id: 'tarp', name: 'ground cloth' }],
  size: [{ id: 'reg', name: 'Regular' }, { id: 'xl', name: 'Xtra Large' }, { id: 's', name: 'Small' }, { id: 'm', name: 'Torso' }, { id: 'l', name: 'Large' }]
};
Bedding.ICON_STRING = 'fa-bed';
export default Bedding;
