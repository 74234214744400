import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import ItemAll from '../containers/Item/item-all-loader';
import ItemCreate from '../containers/Item/item-create-loader';
import ItemEdit from '../containers/Item/item-edit-loader';
import ItemScan from '../containers/Item/item-scan-loader';
import ItemQRScan from '../containers/Item/item-qr-scan-loader';

export const EDIT_ITEM = 'editItemView';
export const CREATE_ITEM = 'addItemView';
export const SCAN_BARCODE_ITEM = 'scanbarItemView';
export const SCAN_QRCODE_ITEM = 'scanqrItemView';
export const PROFILE_ITEM = 'peekItemView';

const itemRoutes = props => {
  const { match, categories, features, featureMap, newItem, activeAccount, inventory, edit, clone, remove } = props;

  const createParams = {
    activeAccount,
    categories,
    inventory,
    features,
    newItem,
    featureMap
  };
  const editParams = {
    ...createParams,
    edit,
    clone,
    remove
  };

  if (!activeAccount || Object.keys(activeAccount).length === 0) {
    return (<Redirect to='/account' />)
  }
  return (
    <Switch>
      <Route path={`${match.path}/all`} render={() => <ItemAll {...editParams} />} />
      <Route path={`${match.path}/create`} render={() => <ItemCreate {...createParams} />} />
      <Route path={`${match.path}/scan`} render={() => <ItemScan {...createParams} />} />
      <Route path={`${match.path}/qr`} render={() => <ItemQRScan {...createParams} />} />
      <Route path={`${match.path}/edit/:id`} render={() => <ItemEdit {...editParams} />} />
      <Route path={`${match.path}/profile/:id`} render={() => <ItemCreate {...editParams} editMode={true} />} />
    </Switch>
  );
}

itemRoutes.propTypes = {
  featureMap: PropTypes.object,
  remove: PropTypes.func,
  match: PropTypes.object,
  edit: PropTypes.func,
  clone: PropTypes.func,
  newItem: PropTypes.func,
  // view: PropTypes.string,
  activeAccount: PropTypes.object,
  inventory: PropTypes.array,
  categories: PropTypes.array,
  features: PropTypes.array
}

itemRoutes.defaultProps = {
  //view:'',
  features: [],
  categories: []
};
export default itemRoutes;
