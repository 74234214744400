import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import TripAll from '../containers/Trip/trip-all-loader';
import TripCreate from '../containers/Trip/trip-create-loader';
import TripEdit from '../containers/Trip/trip-edit-loader';

export const EDIT_TRIP = 'editTripView';
export const CREATE_TRIP = 'addTripView';
export const SCAN_TRIP = 'scanTripView';
export const PROFILE_TRIP = 'peekTripView';

const TripRoutes = props => {
  const { match, categories, features, featureMap, newTrip, activeAccount, inventory, edit, clone, remove, trips, packs, packOptions, activeTrip, forecasts, getForecastData } = props;

  const createParams = {
    activeAccount,
    categories,
    inventory,
    features,
    newTrip,
    activeTrip,
    trips,
    packs,
    packOptions,
    forecasts,
    featureMap,
    getForecastData
  };
  const editParams = {
    ...createParams,
    edit,
    clone,
    remove
  };

  if (!activeAccount || Object.keys(activeAccount).length === 0) {
    return (<Redirect to='/account' />)
  }
  return (
    <Switch>
      <Route path={`${match.path}/create`} render={() => <TripCreate {...createParams} />} />
      <Route path={`${match.path}/edit/:id`} render={() => <TripEdit {...editParams} />} />
      <Route path={`${match.path}/profile/:id`} render={() => <TripCreate {...editParams} editMode={true} />} />
      {/* if none selected, list all trips */}
      <Route render={() => <TripAll {...editParams} />} />
    </Switch>
  );
}

TripRoutes.propTypes = {
  packs: PropTypes.array,
  packOptions: PropTypes.array,
  getForecastData: PropTypes.func,
  forecasts: PropTypes.object,
  featureMap: PropTypes.object,
  remove: PropTypes.func,
  match: PropTypes.object,
  edit: PropTypes.func,
  clone: PropTypes.func,
  newTrip: PropTypes.func,
  trips: PropTypes.array,
  activeTrip: PropTypes.object,
  activeAccount: PropTypes.object,
  inventory: PropTypes.array,
  categories: PropTypes.array,
  features: PropTypes.array
}

TripRoutes.defaultProps = {
  //view:'',
  features: [],
  categories: []
};
export default TripRoutes;
