import ls from 'local-storage';

export const all = (keys = []) => {

    return keys.reduce( (out, i) => {
        const a = ls.get(i);
        if( a ){
            return { ...out, [i]: a };
        }
        return out;
    },{});
}

export const save = (part, val) => {
    return ls.set(part, val);
}

export const get = (part) => {
    return ls.get(part);
}
