
const Sizing = (initial = {}) => {
  const struct = {
    unit: '',
    gender: 'unisex'
  };
  return { ...struct, ...initial };
}

Sizing.ICON_STRING = 'fa-xing';
export const OPTIONS = {
  //unit should provide a dimensional swithc with sizes to return
  //the proper selectors,
  //size 1,2,3 size 32,33,34 size S,M,L etc.
  units: [{ id: 'u', name: 'One Size' }, { id: 's', name: 'S' }, { id: 'm', name: 'M' }, { id: 'l', name: 'L' }, { id: 'xl', name: 'XL' }],
  genders: [{ id: 'u', name: 'unisex' }, { id: 'm', name: 'mens' }, { id: 'w', name: 'womens' }, { id: 'lgbtq', name: 'warped' }]
}
export default Sizing;
