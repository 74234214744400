
import { generate } from "../utils/uuid";
import { crudArrayReducer, addToArray, editToArray, removeFromArray } from '../utils/crud-reducer';

//Constants
export const ACCOUNT_SAVE = 'onAccountSave';
export const ACCOUNT_ADD = 'onAccountAdd';
export const ACCOUNT_REMOVE = 'onAccountDelete';

//Actions
export const addAccount = data => (dispatch, getState) => {
    const t = generate(data)
    let action = {
        type: ACCOUNT_ADD,
        accounts: t
    }
    if (Object.keys(getState().activeAccount).length < 3) {
        console.log('adding activeAccount to the payload');
        action = { ...action, activeAccount: t };
    }
    dispatch(action);
}

export const sync = accounts => {
    return {
        type: ACCOUNT_SAVE,
        accounts
    }
}
//Reducer
export const accounts = crudArrayReducer('accounts', {
    [ACCOUNT_ADD]: addToArray,
    [ACCOUNT_SAVE]: editToArray,
    [ACCOUNT_REMOVE]: removeFromArray
});

export default accounts
