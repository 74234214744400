import { connect } from 'react-redux';
import About from './about';
import ls from 'local-storage';
import featureSet from '../../types/features';

const mapStateToProps = state => {
    return {
        inventory: JSON.stringify(ls.get('inventories')) || ''
    };
}

const mapDispatchToProps = dispatch => {
    return {
        resetAll: () => {
            ls.clear();
        },
        resetAppData: () => {
            ls.remove('categories');
            ls.remove('features');
        },
        clearData: () => {
            ls.remove('inventories');
            Object.keys(featureSet).map(f => ls.remove(f))
        },
        clearAccounts: () => {
            ls.remove('accounts');
            ls.remove('activeAccount');
        },
        dumpJSON: () => { }
    };
}

const AboutView = connect(mapStateToProps, mapDispatchToProps)(About)

export const PAGE_ABOUT = 'aboutPage';
export default AboutView
