
import { ACCOUNT_ADD, ACCOUNT_SAVE } from './account.reduce';

//activeAccount
export const ACCOUNT_CLEAR = 'clearActiveAccount';
export const ACCOUNT_ACTIVE = 'setActiveAccount';

//Actions

/**
 * to connect, the existing activeAccount is cleared
 * before the new account is marked
 */
export const connect = activeAccount => dispatch => {

  dispatch({ type: ACCOUNT_ACTIVE, activeAccount });
}
/**
 *  when clearing the active account, any changes
 *  made to the profile that is not in sync with the accounts[]
 *  will be saved off for clearing
 */
export const exit = accounts => (dispatch, getState) => {
  dispatch({ type: ACCOUNT_SAVE, accounts: getState().activeAccount });
  dispatch({ type: ACCOUNT_CLEAR });
}

//Reducer
const activeAccount = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_ADD:
      console.log('do i have active?');
      if (!action.activeAccount) return state;
    // eslint-disable-next-line no-fallthrough
    case ACCOUNT_ACTIVE:
      console.info(`setting ${action.activeAccount.name} to the activeAccount slot`);
      return action.activeAccount;
    case ACCOUNT_CLEAR:
      //info
      console.info(`removing ${state.name} from the activeAccount slot`);
      return {};
    default:
      return state;
  }
}

export default activeAccount
