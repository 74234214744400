/***********************************************
   Factory
   ***********************************************/
export const validate = ({ tare, unit }) => {
  return (typeof tare === 'number' && tare > 0 && OPTIONS.units.findIndex(o => unit === o.id) !== -1)
}

const Weighs = (initial = {}) => {
  const struct = {
    complete: false,
    gross: 0,
    net: 0,
    tare: 0,
    unit: "lb"
  };
  return { ...struct, ...initial };

}
export const OPTIONS = {
  units: [{ id: 'lb', name: 'Lbs' }, { id: 'oz', name: 'Ounces' }, { id: 'g', name: 'Grams' }]
}
Weighs.ICON_STRING = 'fa-balance-scale';
export default Weighs;
