
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../components/panel';
import inventories from '../../data/inventory.json';
import { computeProfile } from '../../helpers/item.helper';
import { getItemsArray } from '../../helpers/inventory.helper';
/** template supports create and edit  */
export const About = ({ addToInventory, activeAccount, resetAll, resetAppData, clearData, clearAccounts, inventory, dumpJSON, validateItems }) => {

	const injectInventory = (event) => {
		event.preventDefault();
		const items = getItemsArray({inventories});
		if(activeAccount && activeAccount.id){
			addToInventory({owner:activeAccount.id, items});
		}
	}
	const validateAllItems = (event) => {
		event.preventDefault();
	//	const items = computeProfile(getItemsArray({inventories}));
//		addToInventory({owner})
	}

	const _change = (event) => {
		event.preventDefault();
		console.log('baby ive changed');
	};

	return (
		<Panel className="panel-fill col-12">
				<h3>Data Storage</h3>
				<ul>
				<li>
				Completely Wipes everything related to PackIt App
				<button className="btn btn-danger" onClick={resetAll}> Full Reset (Accounts and Settings) </button></li>
				<li>
				Remove all Items across ALL accounts
				<button className="btn btn-danger" onClick={clearData}>Clear All Items from Storage</button></li>
				<li>
				This will clear all training data factors, you are resetting all algorithms to default.
				<button className="btn btn-warning" onClick={resetAppData}>Reset Application Data</button></li>
				<li>
				Clears User Accounts only, (stress test) use to populate inventory with unusable but searchable items
				<button className="btn btn-warning" onClick={clearAccounts}>Clear Accounts</button></li>
				</ul>
				<h3>This is a dump of the local storage in JSON format</h3>
				<textarea name="wanda" value={inventory} onChange={_change}>
				</textarea>
					<h3>Active Account Options</h3>
				<ul><li><button onClick={validateItems} className="btn btn-primary">
					sync system references to current inventory
					</button></li></ul>
				<ul><li><button onClick={injectInventory} className="btn btn-primary">
				  inject starting inventory profiles
					</button></li></ul>
				<ul><li><button onClick={validateAllItems} className="btn btn-primary">
					recompute item profiles
					</button></li></ul>
		</Panel>
	);
}

About.propTypes = {
	activeAccount: PropTypes.object,
	addToInventory: PropTypes.func,
	validateItems: PropTypes.func,
	resetAll: PropTypes.func,
	resetAppData: PropTypes.func,
	clearAccounts: PropTypes.func,
	clearData: PropTypes.func,
	inventory: PropTypes.any,
	dumpJSON: PropTypes.func
};

About.defaultProps = {
	inventory: '',
};
export default About;
