
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WellFlag from '../../components/well-flag';
// import { Link } from 'react-router-dom';
import ItemElement from '../../components/item-element';
import { getItemsByCategory } from '../../helpers/item.helper';
import { inspectFeatureMap } from '../../helpers/category.helper';
import { withRouter } from 'react-router-dom';
import { SelectComp } from '../../components/inputs';
import Panel from '../../components/panel';

export const SYSTEMS_LIFESPAN = 'lifespanSystemView';

class LifespanManage extends Component {

  static propTypes = {
    featureMap: PropTypes.object,
    categories: PropTypes.array,
    history: PropTypes.any,
    activeAccount: PropTypes.object.isRequired,
    inventory: PropTypes.array.isRequired,
    message: PropTypes.string,
    onContinue: PropTypes.func,
    logo: PropTypes.string,
    messages: PropTypes.object
  };

  static defaultProps = {
    message: `This is where your stuff would show its value`,
    items: [],
    history: [],
    featureMap: {},
    messages: {
      emptyCategory: `Looks like you need to add some of this category`, intent: `Lifespan tracks the sturdiness of our posessions and is geared to help in the event of an accident. At some point items are going to break, having to 'retire' an item means you need something can meet its previous uses. Either you have an item you've been using and it graduates to first-to-grab . Retired items are also not completely deleted, image content is stripped, and the item module profile is saved for future queries. retired items generate the life metric allowing for new item comparison to include projected span.
          It can update a wear metric to further the reduction in its projected lifespan. Using a scale of damage and functional loss evaluation a post-trip review would indicate perhaps that its time to update hardwear or suggest something already in the users inventory as a replacement`,
      noPacks: `Backpacks are essential to making use of this app, without it there is no constraints to optimize for`,
      noOutfit: `While you have a few clothing items, you don't quite have a full, let alone mismatched outfit. Adding more clothes to your inventory will make sure you don't go naked on your next trip.`
    }
  };
  //  inventory, activeAccount, history, featureMap, messages, onContinue}) => {
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      itemList: getItemsByCategory('', props.inventory)
    }
  }
  /* redirect to edit view for item */

  _itemLink = event => {
    if (event) {
      this.props.history.push(`/item/edit/${event}`);
    }
  }

  /* new item view */
  _newItem = () => {

    this.props.history.push(`/item/create`);
  }

  onSelectChange = event => {
    if (event.target.value !== 'default') {
      const n = getItemsByCategory(event.target.value, this.props.inventory);
      this.setState({ category: event.target.value, itemList: n });
    } else {
      this.setState({ category: '', itemList: this.props.inventory });
    }
  }

  /* 
    generate item list, 
    consider pagination, sorting by type
  */
  _itemWindow = () => (
    this.state.itemList.map(
      item => <ItemElement onEdit={this._itemLink} key={item.id} {...item} />)
  )

  wank = a => {
    let t = Object.keys(a).map(d => d.parent !== -1)
    return t.length;
  }
  //this is a primitive call 
  hmm = inspectFeatureMap(this.props.featureMap, 'fillable', this.wank)

  render() {
    /* no items prompts notification to user that they can't do shit without any items */
    const _newAccount = this.props.inventory.length || 0;
    const emptyCategory = this.state.itemList.length || 0;

    return (
      <Panel className="systems-lifespan panel-fill" >
        <WellFlag>
          {this.props.messages.intent}
        </WellFlag>
        <SelectComp formId='category' onChange={this.onSelectChange} value={this.state.category} options={this.props.categories} />
        <div className="d-flex flex-wrap">
          <WellFlag hide={emptyCategory !== 0}>
            {this.props.messages.emptyCategory}
          </WellFlag>

          {this._itemWindow()}
        </div>
      </Panel>);
  }
}

export default withRouter(LifespanManage)
