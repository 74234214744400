
const Upf = (initial = {}) => {
  const struct = {
    rating: 0,
    unit: 'upf'
  };
  return { ...struct, ...initial };
}

export const OPTIONS = {
  units: [{ id: 'upf', name: 'UPF' }, { id: 'spf', name: 'SPF' }]
};
Upf.ICON_STRING = 'fa-windows';
export default Upf;
