import { connect } from 'react-redux';
import Trip from '../../routes/trip';
import { requestForecast } from '../../reducers/forecast.reduce';
import { createTrip, editTrip, removeTrip } from '../../reducers/trips.reduce';
import { getItemsArray } from '../../helpers/inventory.helper';
import { getActiveAccount, getAccounts } from '../../helpers/accounts.helper';
import { getTrips, getActiveTrip, getTripsByOwner } from '../../helpers/trip.helper';
import { getPackCollection, getPacks } from '../../helpers/backpack.helper';

// this is an example of how a structured selector would save the trouble of manually entering the same


const forecastSelector = (state) => {
    return state.forecast;
};

const mapStateToProps = state => {
    return {
        /* activeAccount is used as a default but we still want access
        to all owners */
        packs: getPacks(state),
        packOptions: getPackCollection(state),
        inventory: getItemsArray(state),
        forecasts: forecastSelector(state),
        activeTrip: getActiveTrip(state),
        activeAccount: getActiveAccount(state),
        accounts: getAccounts(state),
        /* this will pull regardless of owner */
        trips: getTripsByOwner(getActiveAccount(state).id, state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getForecastData: (geo) => {
            dispatch(requestForecast(geo))
        },
        newTrip: trip => {
            dispatch(createTrip(trip))
        },
        edit: trip => {
            dispatch(editTrip(trip))
        },
        clone: (trip1, trip2) => {
            //   dispatch(cloneTrip(trip1, trip2))
        },
        remove: trip => {
            dispatch(removeTrip(trip))
        }

    };
}

const TripView = connect(mapStateToProps, mapDispatchToProps)(Trip)

export const PAGE_TRIP = 'tripPage';
export default TripView
