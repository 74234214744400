
import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS, REMOVE_ITEM } from '../reducers/item.reduce';
import { crudObjectReducer, removeFromObject, editToObjectDump, editArrayToObjectDump } from '../utils/crud-reducer';
import { UPDATE_FEATURES } from './feature.reduce';

//Selectors
export const getBackpacks = state => state.backpack;
export const getPackById = (state, id) =>
  getBackpacks(state).find(ac => ac.id === id);

const packs = crudObjectReducer('backpack', {
  [CREATE_ITEM]: editToObjectDump,
  [EDIT_ITEM]: editToObjectDump,
  [EDIT_ITEMS]: editArrayToObjectDump,
  [UPDATE_FEATURES]: editArrayToObjectDump,
  [REMOVE_ITEM]: removeFromObject,
});

export default packs
