import { isFreeItem } from "./item.helper";


//Selectors 
export const getItems = state => state.inventories;
export const getItemsArray = state => Object.keys(state.inventories).map(i => state.inventories[i])
export const getItemById = (state, id) =>{
  try{
    return getItems(state)[id];
  }
  catch(e){
    return null;
  }
}
/**
 * 
 * @param {array} inventory 
 */
export const freeItems = (exclude, inventory = []) => {
  return inventory.filter(o =>
    // eslint-disable-next-line eqeqeq
    (isFreeItem(o) && o.id != exclude.id));
}

export const itemById = (r, inventory = []) => {
  // eslint-disable-next-line eqeqeq
  const e = inventory.find(o => o.id == r);
  console.log('r is' + e);
  return e
}
