import { changeItemInspector } from "../utils/change-inspector";
import { getItemsArray } from "../helpers/inventory.helper";

/**
 * Selectors
 */
export const getUnclaimedItems = state => {
    return [];
}
/**
 *
 * @param {String or Array} ref contains one or more category indexes
 * @param {*} inventory store
 */
export const getItemsByCategory = (ref = '', store = []) => {
    if (ref === '') return store;
    let keys = ref;
    if (ref instanceof Array) {
        keys = ref.join();
    }
    return store.filter((o) => String(keys).includes(o.category));
}
/**
 *
 * @param {String or Array} ref contains one or more category indexes
 * @param {*} inventory store
 */
export const getItemsByPropValue = (attr, value = '', store = []) => {
    if (value === '') return store;
    let keys = value;
    if (value instanceof Array) {
        keys = value.join();
    }
    return store.filter((o) => {
        if (o.hasOwnProperty(attr)) {
            return String(keys).includes(o[attr]);
        }
        return false;
    });
}
/**
 * this should really go in inventory helper
 * @param {*} ref
 * @param {*} store
 */
export const getItemsByOwner = (ref, store) => {
    const a = getItemsArray(store)
    //ref

    // eslint-disable-next-line eqeqeq
    return a.filter(o => (o.hasOwnProperty('owner') && o.owner == ref))
}

export const byAttr = attr => m => obj => {
    //console.log(db + ' checking ' + obj + " attr:[" + attr + "]" + obj[attr] + "->val:" + m + ":" + (obj[attr] == m));
    return obj[attr] === m;
}

export const getItemById = byAttr('id');

/**
* Inspector should force the calculation of any derived values
* For item this is A lot! Is this being used? i wonder
* if it is it could be the validation root i have been looking for
*/
export const inspect = o => {
    return { ...o };
}

// updateState will inspect an object and reports to handle item
export const updateState = changeItemInspector('item', inspect);

export const isItem = ({ id, owner, thumb, parent, category, features }) => {
    return (id && owner && thumb && parent && category && features);
}

/**
 * Setting a parent is easy BUT you must remove the items effects from its previous parent
 * @param {*} item
 * @param {*} parent
 */
export const setParent = (item, parent) => {
    //presuming that parent and item are items
    if (isItem(item) && isItem(parent)) {
        return { ...item, parent: parent.id };
    }
    return item;
}

export const setOwner = (owner = '', item) => {
    return { ...item, owner };
}
/**
  * clear the parent field of an item
  */
export const unparent = obj => {
    return { ...obj, parent: -1 };
}
export const isFreeItem = item => {
    return item.parent === -1 && item.category !== '104';
}
