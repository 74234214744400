/**
 * Middleware for app usage targets
 * activeAccount MUST be defined
 * listen to pending action and determine if a new goal has been reached,
 * any goal that already reads true will NOT get reverted back to false,
 * if the conditions leading it to true are no longer valid.
 */
import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS } from '../reducers/item.reduce';
// TODO observe DELETE_ITEMS and DELETE_ITEM
// import { inspect } from '../helpers/goal.helper';

/**
 * each page
 * @param {*} store
 */
const goals = store => next => action => {

  switch (action.type) {

    case CREATE_ITEM:
    case EDIT_ITEM:
    case EDIT_ITEMS:
      //an activeAccount has a profile of acheivements the user needs
      //some of these goals will prevent the user from seeing other aspects of the app
      //this is not the same as a [feature]:complete flag.
      const s = store.getState();
      if (s.activeAccount && s.activeAccount.id && s.activeAccount.id !== -1) {
        //inspect account profile and inject the goal changes.
        //const update = inspect(s.activeAccount, action)
        console.log('checking to see if any goals will be met with this change');
        // action.activeAccount = update;
        //trigger a notification
        //notifyAccount(action)
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return next(action);
  }
}
/*
these are essentially queries against the inventory cache and a pretty good argument twoards using an indexedDB solution
middleware will look at the action event and only evaluate these things on item_create


const funk = {
  //interface first time usage
  //badge traffic indicator -
wearable:{
  covered:()=>{
    //need at least 1 item per body location
  },
  //winter soldier
  //need to be covered and have a thermal rating to support less than 35 degree F
},
versatile:()=>{
  //at least one item that has more than one category purpose
},
prepared:()=>{
//has items that fit the emergency preparedness requirements
//this is going to be a paramter
}
}*/
export default goals
