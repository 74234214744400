import React from 'react';
import PropTypes from 'prop-types';
import PanelRow from './panel-row';

/**
 * Provides an expanding panel with css layout as defined in spec.
 */
const Panel = ({ tabs, labels, children, className }) => {
  const footlabels = labels && labels.length > 2 ? labels.slice(2) : null;
  const foottabs = tabs && tabs.length > 2 ? tabs.slice(2) : null;
  return (
  <div className={`panel ${className}`}>
    <PanelRow components={tabs} className="header-tab" columnStyle="tab"/>
    <div className="panel-body">
    <PanelRow components={labels} className="header" columnStyle="label"/>
      { children }
    <PanelRow components={footlabels} className="foot" columnStyle="footer"/>
    </div>
    <PanelRow components={foottabs} className="header-tab" columnStyle="tab"/>
  </div>
);
};

Panel.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.array,
  labels: PropTypes.array,
  children: PropTypes.node
};
Panel.defaultProps = {
  className: '',
  tabs: [],
  labels: []
};

export default Panel;