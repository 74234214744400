module.exports = function (base64, cb, maxWidth, maxHeight) {


  // Max size for thumbnail
  if (typeof (maxWidth) === 'undefined') maxWidth = 500;
  if (typeof (maxHeight) === 'undefined') maxHeight = 500;

  // Create and initialize two canvas
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  var canvasCopy = document.createElement("canvas");
  var copyContext = canvasCopy.getContext("2d");

  // Create original image
  var img = new Image();
  // Determine new ratio based on max size
  img.onload = function () {
    var ratio = Math.min(maxWidth / img.width, maxHeight / img.height)

    // Draw original image in second canvas
    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    copyContext.drawImage(img, 0, 0);

    // Copy and resize second canvas to first canvas
    canvas.width = Math.floor(img.width * ratio);
    canvas.height = Math.floor(img.height * ratio);
    ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

    cb(canvas.toDataURL())
  }
  img.src = base64;


}
