/**
 * Simplifies the generating and building of an item
 * feature property set
 */
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../components/panel';

export const PackInfo = props => {
  return (
    <Panel className='pack-info panel-fill col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4'>
    This is an in-depth version of the pack cards you will find in pack listing
    show a graph of weight trends,
    damage reports with an estimated lifeleft
    [we could slap a deceased red stamp if the item is 'retired'][show retire]
    how much is in the pack,
    is it linked to a trip?
    show alert for replacement time
    </Panel>)
}
PackInfo.propTypes = {
  goTo: PropTypes.func,
  intent: PropTypes.string,
  packs: PropTypes.array
}
export default PackInfo
