
import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS, REMOVE_ITEM } from '../reducers/item.reduce';
import { crudObjectReducer, editToObjectDump, editArrayToObjectDump, removeFromObject } from '../utils/crud-reducer';
import { UPDATE_FEATURES } from './feature.reduce';

//Selectors 
export const getFillables = state => {
  return Object.keys(state.fillable);
};
export const numFillables = state => {
  return getFillables(state).length;
};

export const getFillableById = (state, id) =>
  getFillables(state).find(ac => ac.id === id);

const fillables = crudObjectReducer('fillable', {
  [CREATE_ITEM]: editToObjectDump,
  [EDIT_ITEM]: editToObjectDump,
  [EDIT_ITEMS]: editArrayToObjectDump,
  [UPDATE_FEATURES]: editArrayToObjectDump,
  [REMOVE_ITEM]: removeFromObject,
});

export default fillables
