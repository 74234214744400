const Perishable = (initial = {}) => {
  const struct = {
    restrict: [],
    expires: -1,
    produced: -1,
    limit: 100,
    limitUnit: "F",
    sensitivity: []
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  units: [{ id: 'F', name: '°F' }, { id: 'C', name: '°C' }],
  conditions: [{ id: 'light', name: 'Light' }, { id: 'heat', name: 'Heat' }, { id: 'humidty', name: 'Humidty' }]
}
Perishable.ICON_STRING = 'fa-thermometer';
export default Perishable
