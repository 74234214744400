/**
 * Application: Packit
 * Purpose: Organization of camping related items
 *
 */

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import App from './routes/App';
//state
import packApp from './reducers'
import storageMiddleware from './middlewares/persistent';
import appFlowMiddleware from './middlewares/goals';
import appHealthMiddleware from './middlewares/apphealth';
import rootSaga from './middlewares/weather.saga';
//default app state
import features from './data/feature.json';
//import inventories from './data/inventory.json';
import categories from './data/category.json';

import { hydrateCache, featureSet } from './helpers/state.helper';
//data caching
import { all } from './utils/local-storage';
//style
import './styles/index.scss';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// instruct localstorage to pull these keys for hydration
let previousState = all(
  [
    'features',
    'categories',
    'accounts',
    'activeAccount',
    'activeTrip',
    'trips',
    'inventories',
    ...featureSet
  ]
);
const sagas = createSagaMiddleware();
const store = createStore(
  packApp,
  hydrateCache(previousState, { features, categories }),
  composeEnhancers(applyMiddleware(thunk, storageMiddleware, appFlowMiddleware, appHealthMiddleware, sagas))
);

sagas.run(rootSaga);
//PackApp
render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
try {
  module.hot.accept();
}
catch (e) { }
