import { crudObjectReducer, editToObjectDump, } from '../utils/crud-reducer';

//Constants
export const GEO_GET = "GET_GEO_POINTS";
export const GEO_SUCCESS = "GEO_POINTS_SUCCESS";
export const GEO_FAIL = "GEO_POINTS_FAIL";
export const FORECAST_GET = "GET_TRIP_FORECAST";
export const FORECAST_FAIL = "GEO_FORECAST_FAIL";
export const FORECAST_SUCCESS = "GEO_FORECAST_SUCCESS";


//Selectors
export const getForecasts = state => state.forecast;
export const getForecastByGrid = (state, grid) =>
  getForecasts(state)[grid] || {};


//Actions
export const requestForecast = (coords) => {
  return {
    type: FORECAST_GET,
    ...coords
  }
}
export const forecastSuccess = data => {
  return {
    type: FORECAST_SUCCESS,
    data
  }
}
export const forecastFail = (data) => {
}
export const getGeoPoints = data => {

}
export const geoPointsSuccess = data => {
  return {
    type: GEO_SUCCESS,
    data
  }
}
export const geoPointsFail = data => {
}

//Reducer
const forecast = crudObjectReducer('forecast', {
  [FORECAST_SUCCESS]: editToObjectDump,
  [GEO_SUCCESS]: editToObjectDump,
});

export default forecast
