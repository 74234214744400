/**
 * Supplies a list of supported control types
 */
import perishable from './perishable.value';
import capacity from './capacity.value';
import portable from './portable.value';
import potable from './potable.value';
import consumable from './consumable.value';
import compartment from './compartment.value';
import insulates from './insulates.value';
import upf from './upf.value';
import resistant from './resistant.value';
import seasonal from './seasonal.value';
import weighs from './weighs.value';
import displaces from './displaces.value';
import sizing from './sizing.value';
import edible from './edible.value';

const controlSet = {
  weighs,
  displaces,
  potable,
  capacity,
  compartment,
  portable,
  seasonal,
  perishable,
  edible,
  consumable,
  insulates,
  upf,
  resistant,
  sizing
}
export default controlSet
