import { combineReducers } from 'redux';
/**
 * Reducers make up the local database
 * unlinke the old angular schema this is not blob object based storage
 * 
 * two are for routing
 *  page- main page component
 *  view- view state for the page
 */

//import page from './page.reduce';
//import view from './view.reduce';
/** these are reference elements for the store
*  categories- each item has a category which dictates features used
*  features- each supported feature has a table and a set of components
*/
import categories from './category.reduce';
import features from './feature.reduce';
/** 
 * every user has an account
 * accounts- usually there is only one user thus accounts.length should be 1
 * activeAccount- this is a draft of the user that will be saved to accounts
 *                at the end of session or major change
 */
import accounts from './account.reduce';
import activeAccount from './activeAccount.reduce';
//like activeAccount item is a placeholder between views
import trips from './trips.reduce';
import activeTrip from './activeTrip.reduce';
/** 
 * features are tabulated creation and edit of items
 * the store is used as a way to speed up the lookup process
 * with what is aessentially a custom Search field
 */
import inventories from './inventories.reduce';
import bedding from './beddings.reduce';
//import capacities from './capacities.reduce';
import compartment from './compartments.reduce';
import cookware from './cookwares.reduce';
//import displaces from './displaces.reduce';
import fillable from './fillables.reduce';
import backpack from './backpacks.reduce';
//import sections from './sections.reduce';
import edible from './edibles.reduce';
//import portables from './portables.reduce';
//import seasonals from './seasonals.reduce';
import shelter from './shelters.reduce';
import wearable from './wearables.reduce';
//import weighs from './weighs.reduce';
import forecast from './forecast.reduce';

/**
 * Root reducer
 */
export const packApp = combineReducers({

  //  view,
  // page,
  accounts,
  categories,
  features,
  activeAccount,
  trips,
  activeTrip,
  inventories,
  bedding,
  //capacities,
  compartment,
  cookware,
  // displaces,
  fillable,
  backpack,
  //sections,
  edible,
  //portables,
  // seasonals,
  shelter,
  wearable,
  // weighs
  forecast
})
export default packApp
