/**
dashboard right now is just a set of links for development purposes
still seems to have its place as status panel, but for what exactly
needs to be determined.
*/
import React from 'react';
import PropTypes from 'prop-types';
import AccountTab from '../Account/account-tab';
import { Link } from 'react-router-dom';
import Panel from '../../components/panel';
import ActiveTripWidget from '../Trip/active-trip-widget';
import HomeLinks from './home-links';

export const HOME_DASHBOARD = 'homeDashboardView';

export const HomeDashboard = ({ items, activeAccount, activeTrip, history, featureMap, messages, onContinue }) => {

  /* redirect if there is no activeAccount defined */
  if (!activeAccount || Object.keys(activeAccount).length === 0) {
    history.push(`/account`);
  }

  return (
    <React.Fragment>
      <AccountTab key="account" className="sticky-top p-2" />
      <Panel className="home-dashboard col-12" >
        <ActiveTripWidget trip={activeTrip} />
        <HomeLinks trip={activeTrip}/>
      </Panel>
    </React.Fragment>);
}

HomeDashboard.propTypes = {
  featureMap: PropTypes.object,
  history: PropTypes.any,
  activeTrip: PropTypes.object.isRequired,
  activeAccount: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  message: PropTypes.string,
  onContinue: PropTypes.func,
  logo: PropTypes.string,
  messages: PropTypes.object
};

HomeDashboard.defaultProps = {
  message: `This is where your stuff would show its value`,
  items: [],
  history: [],
  featureMap: {},
  messages: {
    noItems: `Looks like you need to add some items before we get started. Knowing what resources you have in inventory is more than just a checklist, it's a MacGuyver list!    Besides, it's quick and you can edit just about everything later`,
    noPacks: `Backpacks are essential to making use of this app, without it there is no constraints to optimize for`,
    noOutfit: `While you have a few clothing items, you don't quite have a full, let alone mismatched outfit. Adding more clothes to your inventory will make sure you don't go naked on your next trip.`
  }
};

export default HomeDashboard
