/***********************************************
   Filter
   ***********************************************/
//we call you a filter in angular, you are just a utils in react...
export const supportedUnits = { oz: true, lb: true, g: true };
export const conversionUnits = {
  oz: {
    oz: 1,
    lb: 0.0625,
    g: 28.34952316484755
  },
  lb: {
    lb: 1,
    oz: 16,
    g: 453.5923703803783
  },
  g: {
    g: 1,
    oz: 0.035273961900000005,
    lb: 0.00220462262
  }
};

export const weightConvertFromTo = (weighs, from, to) => {
  //should check with supportedUnits before calcing
  return Number(weighs || 0) * conversionUnits[from][to];
}

export default weightConvertFromTo
