/**
   * Displaces Object
   * defines the object data parameters
   * should offer the ability to discern options for those parameters
   * TODO: determine data structure, should there be a template object
   *       and a validator object to match?
   */

const Cookware = (initial = {}) => {
  const struct = {
    complete: false,
    purpose: '',
    type: ''
  };
  return { ...struct, ...initial };
}
export const OPTIONS = {
  purpose: [
    { id: 'stove', name: 'stove', types: 'stove' },
    { id: 'pan', name: 'Pan', types: 'heat' },
    { id: 'pot', name: 'Pot', types: 'heat' },
    { id: 'cup', name: 'Cup', types: 'general' },
    { id: 'plate', name: 'Plate', types: 'general' },
    { id: 'utensil', name: 'Sporktensils', types: 'general' },
    { id: 'food-store', name: 'Food Storage', types: 'general' }
  ],
  types: {
    stove: [{ id: 'propane', name: 'Propane' }, { id: 'alcohol', name: 'alcohol' }, { id: 'wood', name: 'Wood' }],
    heat: [{ id: 'aluminum', name: 'Aluminum' }, { id: 'cast', name: 'cast iron' }, { id: 'titanium', name: 'Titanium' }],
    general: [{ id: 'aluminum', name: 'Aluminum' }, { id: 'plastic', name: 'plastic' }, { id: 'silicone', name: 'Silicone' }, { id: 'titanium', name: 'Titanium' }]
  }
};
Cookware.ICON_STRING = 'fa-free-code-camp';
export default Cookware;
