/**
 * This component resolves routes related to account flow
 * /create
 * /edit (TODO: support delete)
 * /select
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, Redirect } from 'react-router-dom';
// create is an instance of edit with an empty profile
import AccountCreate from '../containers/Account/account-create';
import AccountEdit from '../containers/Account/account-edit';
import AccountSelect from '../containers/Account/account-select';
import AccountLogout from '../containers/Account/account-logout';
import { accountById } from '../helpers/accounts.helper';
export const EDIT_ACCOUNT = 'editAccountView';
export const SELECT_ACCOUNT = 'selectAccountView';
export const CREATE_ACCOUNT = 'createAccountView';

/** template supports create and edit  */
/** account select could merely be a select component but separating it allows for
 * a different experience for that specific UX later down the road
 */
/* we ship account as a functional component and wrap our state in the container folder  */

export const Account = props => {

  const {
    history,
    match,
    accounts,
    activeAccount,
    createAccount,
    editAccount,
    setActiveAccount,
  } = props;

  const editParams = {
    accounts,
    history,
    activeAccount,
    editAccount
  }

  const selectParams = {
    ...editParams,
    setActiveAccount
  }

  /* dispatch activeAccount action */
  const _updateMe = event => {
    console.log('updateme ' + event.target.value);
    const a = accountById(event.target.value, accounts);
    if (a) setActiveAccount(a);
  }

  const _handleCreate = data => {
    createAccount(data);
    history.push('/home');
  }

  const _handleEdit = data => {
    editAccount(data);
    //
    history.push('/home');
    //probably don't need to set active
    //setActiveAccount(data);
  }

  const _handleSelect = data => {
    //this is a double lookup, need to decide on upper or lower level resolve
    const a = accountById(data, accounts);
    if (a) setActiveAccount(a);
  }


  /* activeAccount will always contain a profile it will just have an invalid id
  edit: you can only edit the currently selected account, you have to use select
  to do so
  */
  return (
    <Switch>
      <Route path={`${match.path}/create`} render={() => <AccountCreate newAccount={_handleCreate} />} />
      <Route path={`${match.path}/edit/:id`} render={() => <AccountEdit handleSubmit={_handleEdit} {...editParams} />} />
      <Route path={`${match.path}/select`} render={() => <AccountSelect update={_updateMe} handleSubmit={_handleSelect}  {...selectParams} />} />
      <Route path={`${match.path}/logout`} render={() => <AccountLogout update={_updateMe} handleSubmit={_handleSelect}  {...selectParams} />} />
      <Route path={`${match.path}`} exact render={() => {

        if (accounts.length > 0) {
          if (!activeAccount || Object.keys(activeAccount).length === 0) {
            console.log('[CATCH] accounts plenty')
            return (<Redirect to={`${match.path}/select`} />)
          } else {
            console.log('[CATCH] current user lost?')
            return (<Redirect to={`/home`} />)
          }
        }
        /*
        if(activeAccount.id && activeAccount.id > 0){
          console.log('[CATCH] activeAccount')
          return (<Redirect to={`${match.path}/edit/${activeAccount.id}`} />)
        }*/

        console.log('[CATCH] accounts empty')
        return (<Redirect to={`${match.path}/create`} />)
      }} />
    </Switch>
  );
};

Account.propTypes = {
  //who knows
  history: PropTypes.any,
  match: PropTypes.object,
  // view:PropTypes.string,
  //redux states
  accounts: PropTypes.array,
  activeAccount: PropTypes.object,
  //redux actions

  createAccount: PropTypes.func, //create
  setActiveAccount: PropTypes.func,//select 
  editAccount: PropTypes.func//edit 
};

Account.defaultProps = {
  create: false,
  accounts: [],
  activeAccount: {}
};
export default Account;
