
import Features from '../types/features';

export const featureSet = Object.keys(Features);
//BUG localStorage will set things to null if they don't exist

/**
 * These methods requires root access to the store
 * TODO note that the absence of a value is the same result as having a value
 * of zero. Add isInit to make the methods more predictable.
 * @param {*} o 
 */
//isInit([features,category])
export const hasAppData = o => {
  return (isDefined(o, 'features') && isDefined(o, 'categories'));
}
//isInit accounts
export const hasUserAccounts = o => {
  return (isDefined(o, 'accounts') && o.accounts.length > 0);
}
export const isSingleUser = o => {
  return (isDefined(o, 'accounts') && o.accounts.length === 1);
}
//isInit inventories
export const hasInventory = o => {
  return isDefined(o, 'inventories') && Object.keys(o.inventories).length > 0;
}
export const currentView = o => {
  return o.view;
}

export const hasMultipleAccounts = o => {
  return (isDefined(o, 'accounts') && o.accounts.length > 1) || false;
}
//yes, magic-number swap with sessionValidator
export const hasPreviousSession = o => {
  return isDefined(o, 'activeAccount') && Object.keys(o.activeAccount).length > 5;
}
export const hasPacks = o => {
  return (isDefined(o, 'packs') && o.packs instanceof Array && o.packs.length > 0);
}
export const allowSkip = (o, fork) => {
  return o.activeAccount.preferences[fork] || false;
}
export const isDefined = (o, prop) => {
  return (o && o.hasOwnProperty(prop) && o[prop] != null);
}
/**
 * Normalize the state tree based on a few questions
 * Intro page is the start of the new user flow
 * 
 * @param {*} previousState 
 * @param {*} defaultState 
 */
export const hydrateCache = (previousState, defaultState) => {
  let hydratedState = { ...previousState };
  if (!hasAppData(previousState)) {
    hydratedState = {
      ...hydratedState,
      ...defaultState,
      //    page:PAGE_INTRO
    };
    console.info('@# !AppData')
  }
  if (!hasInventory(previousState)) {
    //initialize inventories, the reducers do this but     
    console.info('@# !Inventory');
    hydratedState.inventories = defaultState.inventories || {};
  }
  if (hasUserAccounts(previousState)) {

    /*if(hasPreviousSession(previousState)){

      //most common single account flow
      hydratedState = {
        ...hydratedState,
        page:PAGE_HOME,
        view:HOME_DASHBOARD
      };
    }else{*/
    console.info('@# !PreviousSession - connecting as ' + previousState.activeAccount.name)
    hydratedState = {
      ...hydratedState,
      activeAccount: { ...previousState.activeAccount },
      //  page:PAGE_HOME, 
      //  view:HOME_DASHBOARD
    };
    //}

    //select overrides a previous session
    //multi accounts is so rare there must be a reason for it
   // if (hasMultipleAccounts(previousState)) {
      //accounts.length > 1
   ////   hydratedState = {
   //     ...hydratedState,
    //    activeAccount: {},
        // page:PAGE_ACCOUNT,  
        // view:SELECT_ACCOUNT
  //    };
   // }
  } else {
    //accounts.length === 0
    console.info('@# !UserAccounts')
    hydratedState = {
      ...hydratedState,
      accounts: [],
      activeAccount: {},
      //page:PAGE_ACCOUNT,
      //view:CREATE_ACCOUNT
    };
  }

  return hydratedState;
}
