/**
 * consumable is the solid version of fillable, you eat servings
 * that decrement a quantity rating.
 * this would make consumable a minor property 
 * @param {*} initial 
 */
const Consumable = (initial = {}) => {

  const struct = {
    servings: 0,
    quantity: 0,
    refill: 0,
    status: 'danger'
  }
  return { ...struct, ...initial };
}
Consumable.ICON_STRING = 'fa-pie-chart';
export default Consumable
