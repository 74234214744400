/**
 * Supplies a list of supported feature types
 */
import bedding from './bedding.value';
import fillable from './fillable.value';
import cookware from './cookware.value';
import compartment from './compartment.value';
import backpack from './backpack.value';
import wearable from './wearable.value';
import shelter from './shelter.value';
import edible from './edible.value';

const featureSet = {
  fillable,
  bedding,
  cookware,
  compartment,
  backpack,
  wearable,
  shelter,
  edible
}
export default featureSet
