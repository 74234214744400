/*
DEPRECATE?
This is pretty old stuff and the functionality can be found in
compartment and sections already

  returns filtered result of inventory
  item must be detached (no parent)
  item category cannot be a backpack
  //104 is hardcoded breakage will occur if category id changes
*/
import { inspect as inspectWeight } from './weighs.helper';
import { inspect as inspectDisplace } from './displaces.helper';
import DisplaceFilter from './displaces.filter';
import WeightFilter from './weighs.filter';
import { VolumeCount, MassCount } from '../types/units';

export const getActiveManifest = (state, packId) => {
  return {};
}

export const getPreviousManifests = (state, packId) => {
  return [];
}

/**
 apply the manifest id to items in content
 @param man:Manifest
 @param contents:Array of ItemObject
*/
export const commit = (man, contents = []) => {
  let c = [...man];

  if (contents.length) {
    //this totally doesn't do shit about duplicate ids
    c = contents.filter(a => {
      if (a.id) {
        return true;
      }
      return false;
    }
    );
  }
  return c;
}

/*
  Note that this actually requires a manifest profile, not a simple array
  @param man:Manifest
  @return Array presumably of type Item
 */

export const itemsIn = (man, svc = () => { }) => {
  let b = [];
  if (man.hasOwnProperty('contents')) {
    man.contents.map((itm) => {
      let c = svc(itm);
      b.push(c);
    });
  }
  //console.log('ManifestService.contentsOf length:' + b.length);
  return b;
}


export const addItem = (man, i) => {

  if (isNaN(i)) {
    // i.parent = man.id;
    man.push(i);
  }
  //updateMetrics(man);
}

/*
  man:Array of Items
  i:Item
 */

export const removeItem = (man, i) => {
  if (isNaN(i)) {

    let findex = man.findIndex(
      (obj) => {
        return i === obj;
      }
    );
    if (findex >= 0) {
      console.debug('remove, found item ' + i.name);
      man.splice(findex, 1);
      // i.parent = -1;
    } else {
      console.debug('unable to locate item ' + i.name);
    }
  }
  //updateMetrics(man);
}


export const updateMetrics = man => {
  man.gross = getWeight(man).value;
  man.displaces = getVolume(man).value;
}


export const getVolume = content => {
  let result = VolumeCount();
  let acc = 0;
  //each item in a manifest could potentially have volume displacement
  content.map((obj) => {
    let ins = inspectDisplace(obj.options.displaces);
    let t = DisplaceFilter(
      ins.value,
      ins.unit,
      result.unit);
    if (t === 0) {
      acc++;
    }
    result.value += t;
  });
  result.accuracy = (acc / content.length) * 100;
  //console.log('ManifestService.sumVolume ' +
  //           result.accuracy +
  //           ' of ' +
  //           content.length);
  return result;
}


export const getWeight = content => {
  let result = MassCount();
  let acc = 0;
  content.map((obj) => {
    let ins = inspectWeight(obj.options);
    let t = WeightFilter(
      ins.value,
      ins.unit,
      result.unit);

    if (t === 0) {
      acc++;
    }

    result.value += t;
  });
  result.accuracy = (acc / content.length) * 100;
  // console.log('ManifestService.sumWeight ' +
  //             result.accuracy +
  //             ' of ' +
  //             content.length);
  return result;
}
