
import { connect } from 'react-redux';
import Account from '../../routes/account';
import { sync, addAccount } from '../../reducers/account.reduce';
import { connect as setActive } from '../../reducers/activeAccount.reduce';
import { getActiveAccount, getAccounts } from '../../helpers/accounts.helper';

const mapStateToProps = state => {
    return {
        accounts: getAccounts(state),
        activeAccount: getActiveAccount(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        createAccount: acc => {
            dispatch(addAccount(acc))
        },
        setActiveAccount: accId => {
            dispatch(setActive(accId))
        },
        //should we use this point to coerce data structure?
        editAccount: changes => {
            dispatch(sync(changes))
        }
    };
}

const AccountView = connect(mapStateToProps, mapDispatchToProps)(Account)

export const PAGE_ACCOUNT = 'accountPage';
export default AccountView;
