
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WellFlag from '../../components/well-flag';
import { connect } from 'react-redux';
import ItemElement from '../../components/item-element';
import { getItemsByCategory } from '../../helpers/item.helper';
import { inspectFeatureMap } from '../../helpers/category.helper';
import { withRouter } from 'react-router-dom';
import { getFillables } from '../../reducers/fillables.reduce';
import { compose } from 'redux';
import Panel from '../../components/panel';
import { SelectComp } from '../../components/inputs';

export const SYSTEMS_TRAVEL = 'travelSystemView';

class TravelManage extends Component {
  //  inventory, activeAccount, history, featureMap, messages, onContinue}) => {
  constructor(props) {
    super(props);

    this.state = {
      category: '',
      itemList: getItemsByCategory('', props.inventory)
    }
  }
  /* redirect to edit view for item */

  _itemLink = event => {
    if (event) {
      this.props.history.push(`/item/edit/${event}`);
    }
  }

  /* new item view */
  _newItem = () => {

    this.props.history.push(`/item/create`);
  }


  /* 
    generate item list, 
    consider pagination, sorting by type
  */
  _itemWindow = () => (
    this.state.itemList.map(
      item => <ItemElement onEdit={this._itemLink} key={item.id} {...item} />)
  )

  wank = a => {
    let t = Object.keys(a).map(d => d.parent !== -1)
    return t.length;
  }
  //this is a primitive call 
  hmm = inspectFeatureMap(this.props.featureMap, 'fillable', this.wank)

  render() {
    return (
      <Panel className="systems-travel panel-fill" >
        <WellFlag>
          {this.props.messages.intent}
        </WellFlag>
        <SelectComp formId='category' onChange={this.onSelectChange} value={this.state.category} options={this.props.categories} />
        <div className="d-flex flex-wrap">
          <WellFlag>
            {this.props.messages.emptyCategory}
          </WellFlag>

          {this._itemWindow()}
        </div>
      </Panel>);
  }
}

const mapStateToProps = state => {
  return {
    //  items:getItemsByOwner(state.activeAccount.id, state),
    numFillable: getFillables(state)
  };
};

TravelManage.propTypes = {
  numFillable: PropTypes.any,
  featureMap: PropTypes.object,
  categories: PropTypes.array,
  history: PropTypes.any,
  activeAccount: PropTypes.object.isRequired,
  inventory: PropTypes.array.isRequired,
  message: PropTypes.string,
  onContinue: PropTypes.func,
  logo: PropTypes.string,
  messages: PropTypes.object
};

TravelManage.defaultProps = {
  message: `This is where your stuff would show its value`,
  items: [],
  history: [],
  featureMap: {},
  messages: {
    emptyCategory: `Looks like you need to add some of this category`,
    intent: `Travel systems surround the HOW of getting there, [backpack] where did you put your stuff? in a hand bag and then carried that across a rickety bridge on a unicycle? [compartment] did you put your aftershave right next to your food or did you break up your things into LOGICAL bags? [cookware] you travelled, but did you COLD camp, licking the last bit of PB and J from the plastic bag? [gear] did you use trekking poles, did you bring a raft? gps? im right here for ya!`,
    noPacks: `Backpacks are essential to making use of this app, without it there is no constraints to optimize for`,
    noOutfit: `While you have a few clothing items, you don't quite have a full, let alone mismatched outfit. Adding more clothes to your inventory will make sure you don't go naked on your next trip.`
  }
};
const withConnect = connect(mapStateToProps);
export default compose(withRouter, withConnect)(TravelManage)
