
const Insulates = (initial = {}) => {
  const struct = {
    //we will leave rating as a number but there could be alphas
    rating: 0,
    unit: 'r',
    material: 'none'
  };
  return { ...struct, ...initial };
};

export const OPTIONS = {
  materials: [{ id: 'none', name: 'none' }, { id: 'synthetic', name: 'Synthetic Insulation' }, { id: 'down', name: 'Goose down' }, { id: 'foam', name: 'Foam (closed cell)' }, { id: 'rubber', name: 'Rubber' }],
  units: [{ id: 'r', name: 'R-Factor (common)' }, { id: 'f', name: 'Fahrenheit' }, { id: 'c', name: 'Celcius' }, { id: 'fill', name: 'Fill Power' }, { id: 'btu', name: 'BTU' }]
}
// Recommended sleeping pad R - values:

//   Summer - only backpacking: 1 to 3 50F-80 80-110
// 3 - Season backpacking: 3 to 5
// Winter camping on snow: 5 + -20-

Insulates.ICON_STRING = 'fa-align-center';
export default Insulates;
