
/**
 * features meta store
 * there is no interaction with the store other than get for now
 * the value prop is usually a number but could also be a link in the future
 * ie https://packit.mokee.org/features/[value]
 */
import { crudArrayReducer, addToArray, editToArray, removeFromArray } from '../utils/crud-reducer';
//let uid;
//activeAccount action constants
export const FEATURE_ADD = 'onFeatureAdd';
export const FEATURE_EDIT = 'onFeatureEdit';
export const FEATURE_REMOVE = 'onFeatureRemove';
export const UPDATE_FEATURES = 'updateFeatureTables';

//Selectors 
export const getFeatures = state => state.features;
export const getFeatureById = (state, id) =>
    getFeatures(state).find(ac => ac.id === id);

export const addFeature = data => {
    return {
        type: FEATURE_ADD,
        data
    }
}
export const editFeature = data => {
    return {
        type: FEATURE_EDIT,
        data
    }
}
export const removeFeature = data => {
    return {
        type: FEATURE_REMOVE,
        data
    }
}

export const features = crudArrayReducer('features', {
    [FEATURE_ADD]: addToArray,
    [FEATURE_EDIT]: editToArray,
    [FEATURE_REMOVE]: removeFromArray,
});

export default features
