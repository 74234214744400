/**
 * Trip report
 * collect an index of items used (master manifest)
 * collect trip length (days), mileage, environment (temp, moisture, sun)
 * collect trip strenuous level (0-9)
 * collect damage data (check of with a (minor(scratches, dings), moderate(duct tape or sew), repair(out of commission) , replace)
 * collect consumable data (food, water, disposables)
 */

//Selectors
export const getActiveTrip = state => state.activeTrip || {};
export const getTrips = state => {
  if (state.trips) {
    return Object.keys(state.trips).map(i => state.trips[i]);
  }
  return [];
}

export const mostRecentlyUsed = state => {
  const o = mostRecent(getTrips(state));
  return (o && o.pack) || '';
}

export const getTripById = (state, id) =>
  getTrips(state).find(trip => trip.id === id);

export const mostRecent = (a = []) => {
  return a.reduce((trip, out)=>{
    if(trip && out.endDate < trip.endDate) return trip;
  },{});
}
export const tripById = (r, list = []) => {
  // eslint-disable-next-line eqeqeq
  const e = list.find(o => o.id == r);
  console.log('trip is' + e);
  return e
}

export const getTripsByOwner = (id, state) => {
  if (id && state.trips) {
    const k = Object.values(state.trips);
    if (k.length > 0)
      return k.filter(p => p.owner && p.owner === id)
  }
  return [];
}
/**
 *
 * @param {String or Array} ref contains one or more category indexes
 * @param {*} trip store
 */
export const getTripsByCategory = (ref = '', store = []) => {
  if (ref === '') return store;
  let keys = ref;
  if (ref instanceof Array) {
    keys = ref.join();
  }
  return store.filter((o) => String(keys).includes(o.type.type));
}
export const sortByPack = (id, state) => {
  const t = getTripsByOwner(id, state)
  return t.reduce((out, a) => {
    if (a.hasOwnProperty('pack') && out.hasOwnProperty(a['pack'])) {
      out[a['pack']] = [...out[a['pack']], a];
    } else {
      // add pack and then trip
      out[a['pack']] = [a];
    }
    return out;
  }, {});
}

/* list will be used for damage, consumable, ratings */
export const itemList = () => {
}
/* environment, gps, length,  */
export const tripReport = () => {
}

/* activity level, duration, metrics */
export const activityReport = () => {
}

/* damage reduces item life factor, and can disable an inventory item */
export const damageReport = () => {
}
/* when stuff gets eaten, weight drops, calories get consumed, */
export const consumableReport = () => {
}
