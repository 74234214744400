/**
 * A Badge displays a symbol on a background of your choosing
*/
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
//import fontAwesome;
export const STATUS = ['primary', 'secondary', 'danger', 'warning', 'info', 'success', 'light', 'dark'];

const Badge = props => {
    const {children, isAnimated, icon, hide, status} = props;
    const down = {
            isAnimated,
            icon
        };
    return ( 
    <div className={classnames('badge',`badge-${status}`,{'hidden':hide})}>
    <BadgeIcon {...down}/>
    {children}
    </div>
    );
}
/**
 *
 */
export const InlineBadge = props => {
    const {isAnimated, icon, hide, status, children} = props;
    const down = {
        isAnimated,
        icon    
    };
    return (
    <span className={classnames('badge', `badge-${status}`,{'hidden':hide})}>
    <BadgeIcon {...down} />
    {children}
    </span>
    );
}

export const BadgeIcon = ({icon,isAnimated}) => (
    <React.Fragment>
    <i className={classnames('fa fa-fw',  icon, {'shake-icon':isAnimated})}  />
    </React.Fragment>
);

Badge.propTypes = {
    isAnimated:PropTypes.bool,
    hide:PropTypes.bool,
    icon:PropTypes.string,
    status:PropTypes.string,
    children:PropTypes.node
};
InlineBadge.propTypes = {
    isAnimated:PropTypes.bool,
    hide:PropTypes.bool,
    icon:PropTypes.string,
    status:PropTypes.string,
    children:PropTypes.node
};
BadgeIcon.propTypes = {
    icon:PropTypes.string,
    isAnimated:PropTypes.bool
};

Badge.defaultProps = {
    status:'light',
    isAnimated:false,
    icon:'',
    hide:false
}
InlineBadge.defaultProps = {
    status:'light',
    isAnimated:false,
    icon:'',
    hide:false
};
Badge.defaultProps = {
    status:'light',
    hasData:false,
    isAnimated:false,
    icon:''
}
export default Badge;