import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectComp } from '../../components/inputs';
import WellFlag from '../../components/well-flag';
import { accountById } from '../../helpers/accounts.helper';
import AccountFactory from '../../types/account.value';
import Panel from '../../components/panel';
import MetaDisplay from '../../components/meta-display';

/**
 * AccountSelect
 * @UXFlowId: a2
 * user inputs: account
 * actions: handleSubmit
 * transition: history.back
 */
class AccountLogout extends Component {
  //	const {account, accounts, history, message,  handleSubmit} = props;
  //let selected = account;
  constructor(props) {
    super(props);
    this.state = {
      account: props.activeAccount,
      index: props.activeAccount.id || undefined
    }
  }
  _update = event => {
    let t = accountById(event.target.value, this.props.accounts);
    if (t) this.setState({ account: t, index: event.target.value });
  };

	/**
	 * TODO: best practice? rely on a success response and redirect at render
	 * or keep the current action of hitting back regardless of result?
	 */
  _butt = () => {
    this.props.handleSubmit(this.state.account.id);
    //history.goBack();
    this.props.history.push('/home');
  }

  render() {
    const acc = Boolean(this.state.account);
    const a = acc ? this.state.account : AccountFactory();
    return (
      <Panel className="account-logout panel-fill col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4">
        <MetaDisplay name={a.name} image={a.avatar} />
        <WellFlag hide={false}>
          {this.props.message}
        </WellFlag>
        <SelectComp className='custom-select' label="Account" formId='account' value={this.state.index} onChange={this._update} options={this.props.accounts} />
        <div className="btn-group">
          <button onClick={this._butt} disabled={!acc} className="btn btn-primary" title="Continue" tooltip="Continue as {account.name}">
            Continue
				</button>
        </div>
      </Panel>
    )
  }
};

AccountLogout.propTypes = {
  accounts: PropTypes.array,
  activeAccount: PropTypes.any,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  message: PropTypes.string
};

AccountLogout.defaultProps = {
  accounts: [],
  message: `Thumper says OK fine, you don't want to interact or maybe you have another account you want to manage `
};
export default AccountLogout;
