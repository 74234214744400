
const Resistant = (initial = {}) => {
  const struct = {
    waterproof: 0,
    windproof: 0
  };
  return { ...struct, ...initial };
}

Resistant.ICON_STRING = 'fa-tint';
export default Resistant;
