
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../components/panel';
/** template supports create and edit  */
export const About = ({ resetAll, resetAppData, clearData, clearAccounts, inventory, dumpJSON }) => {

	return (
		<Panel className="about panel-fill col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4">
			I am now a screen that shows a happy backpacking rabbit
			and a lot of legal text and links to services that are free
			as long as you dont use them too much
		</Panel>
	);
}

About.propTypes = {
	resetAll: PropTypes.func,
	resetAppData: PropTypes.func,
	clearAccounts: PropTypes.func,
	clearData: PropTypes.func,
	inventory: PropTypes.any,
	dumpJSON: PropTypes.func
};

About.defaultProps = {
	inventory: 'string',
};
export default About;
