
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WellFlag from '../../components/well-flag';
import { connect } from 'react-redux';
import ItemElement from '../../components/item-element';
import { getItemsByCategory } from '../../helpers/item.helper';
import { inspectFeatureMap } from '../../helpers/category.helper';
import { withRouter } from 'react-router-dom';
import { getFillables } from '../../reducers/fillables.reduce';
import { compose } from 'redux';
import Panel from '../../components/panel';
import { SelectComp } from '../../components/inputs';

export const SYSTEMS_HYDRATE = 'hydrateSystemView';

class HydrateManage extends Component {
  //  inventory, activeAccount, history, featureMap, messages, onContinue}) => {
  constructor(props) {
    super(props);

    this.state = {
      category: '',
      itemList: getItemsByCategory('', props.inventory)
    }
  }
  /* redirect to edit view for item */

  _itemLink = event => {
    if (event) {
      this.props.history.push(`/item/edit/${event}`);
    }
  }

  /* new item view */
  _newItem = () => {

    this.props.history.push(`/item/create`);
  }


  /* 
    generate item list, 
    consider pagination, sorting by type
  */
  _itemWindow = () => (
    this.state.itemList.map(
      item => <ItemElement onEdit={this._itemLink} key={item.id} {...item} />)
  )

  wank = a => {
    let t = Object.keys(a).map(d => d.parent !== -1)
    return t.length;
  }
  //this is a primitive call 
  hmm = inspectFeatureMap(this.props.featureMap, 'fillable', this.wank)

  render() {
    return (
      <Panel className="systems-hydrate panel-fill" >
        <WellFlag>
          {this.props.messages.intent}
        </WellFlag>
        <SelectComp formId='category' onChange={this.onSelectChange} value={this.state.category} options={this.props.categories} />
        <div className="d-flex flex-wrap">
          <WellFlag>
            {this.props.messages.emptyCategory}
          </WellFlag>

          {this._itemWindow()}
        </div>
      </Panel>);
  }
}

const mapStateToProps = state => {
  return {
    //  items:getItemsByOwner(state.activeAccount.id, state),
    numFillable: getFillables(state)
  };
};

HydrateManage.propTypes = {
  numFillable: PropTypes.any,
  featureMap: PropTypes.object,
  categories: PropTypes.array,
  history: PropTypes.any,
  activeAccount: PropTypes.object.isRequired,
  inventory: PropTypes.array.isRequired,
  message: PropTypes.string,
  onContinue: PropTypes.func,
  logo: PropTypes.string,
  messages: PropTypes.object
};

HydrateManage.defaultProps = {
  message: `This is where your stuff would show its value`,
  items: [],
  history: [],
  featureMap: {},
  messages: {
    emptyCategory: `Looks like you need to add some of this category`,
    intent: `Hydrate dictate your travel range and are usually the most dense item in your backpack. It can also determine whether you got to bed with a hot meal and liquids to wash down your dessert or you cold camp it. Trips define these needs by distance traversed, refill points and duration. You need enough fuel, you need enough water capacity to make it between legs`,
    noPacks: `Backpacks are essential to making use of this app, without it there is no constraints to optimize for`,
    noOutfit: `While you have a few clothing items, you don't quite have a full, let alone mismatched outfit. Adding more clothes to your inventory will make sure you don't go naked on your next trip.`
  }
};
const withConnect = connect(mapStateToProps);
export default compose(withRouter, withConnect)(HydrateManage)
