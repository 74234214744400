/**
 * Middleware for saving the app state in the background
 */
import ls from 'local-storage';
import { CREATE_ITEM, EDIT_ITEM, EDIT_ITEMS, REMOVE_ITEM, VALIDATE_ITEMS, ASSIGN_ITEMS } from '../reducers/item.reduce';
import { ACCOUNT_SAVE, ACCOUNT_ADD, ACCOUNT_REMOVE } from '../reducers/account.reduce';
import { PACK_EDIT } from '../reducers/pack.reduce';
import { TRIP_ADD, TRIP_SAVE, TRIP_REMOVE } from '../reducers/trips.reduce';
import { ACCOUNT_CLEAR, ACCOUNT_ACTIVE } from '../reducers/activeAccount.reduce';
import { TRIP_CLEAR } from '../reducers/activeTrip.reduce';

export const persistent = store => next => action => {
  let save = false;
  switch (action.type) {
    case CREATE_ITEM:
    case EDIT_ITEM:
    case EDIT_ITEMS:
    case REMOVE_ITEM:
    case ASSIGN_ITEMS:
    case VALIDATE_ITEMS:
    case ACCOUNT_ADD:
    case ACCOUNT_SAVE:
    case ACCOUNT_ACTIVE:
    case ACCOUNT_REMOVE:
    case ACCOUNT_CLEAR:
    case TRIP_ADD:
    case TRIP_SAVE:
    case TRIP_CLEAR:
    case TRIP_REMOVE:
    case PACK_EDIT:
      save = true;
      console.log('persistent middleware saving on event')
    // eslint-disable-next-line no-fallthrough
    default:
      next(action);
      break;
  }

  if (save) {
    const update = store.getState();
    let tally = '';
    // eslint-disable-next-line array-callback-return
    Object.keys(update).map(key => {
      ls.set(key, update[key]);
      tally += '-' + key;
    });
    return tally;
    //console.log('[SAVE] ' + tally);
  }
}
export default persistent
