import { connect } from 'react-redux';
import Debug from './debug';
import ls from 'local-storage';
import featureSet from '../../types/features';
import { validateItems as sync, assignItems } from '../../reducers/item.reduce';
import { getActiveAccount } from '../../helpers/accounts.helper';
const mapStateToProps = state => {
    return {
        activeAccount: getActiveAccount(state),
        inventory: JSON.stringify(ls.get('inventories')) || ''
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        validateItems: (event) => {
            event.preventDefault()
            dispatch(sync())
        },
        addToInventory: (t) => {
            dispatch(assignItems(t))
        },
        resetAll: () => {
            ls.clear();
        },
        resetAppData: () => {
            ls.remove('categories');
            ls.remove('features');
        },
        clearData: () => {
            ls.remove('inventories');
            Object.keys(featureSet).map(f => ls.remove(f))
        },
        clearAccounts: () => {
            ls.remove('accounts');
            ls.remove('activeAccount');
        },
        dumpJSON: () => { }
    };
}

const DebugView = connect(mapStateToProps, mapDispatchToProps)(Debug)

export const PAGE_ABOUT = 'debugPage';
export default DebugView
