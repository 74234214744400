import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { BadgeIcon } from '../../components/badge';
import classnames from 'classnames';
import AccountModal from './account-modal';
//probably will refactor <img> to some component 

/**
 * Badge displaying user avatar and action emitter
 * @param {*} props 
 */
class AccountWidget extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false
    };
    this.list = [{
      link: '/trip',
      title: 'Trip Planning',
      text: 'Trips',
      badge: this.props.tripCount
    }, {
      link: '/pack',
      title: 'See your bags and inspect/add things',
      text: 'Packs',
      badge: this.props.packCount
    }, {
      link: '/item/all',
      title: 'Look at all the things you have, delete stuff you donated, add more',
      text: 'My Stuff',
      badge: this.props.itemCount
    }, {
      link: '/debug',
      title: 'Wipe out data',
      text: 'Settings',
    }, {
      link: '/about',
      title: 'Terms and Conditions',
      text: 'About PackIt',
    },];
  }
  handleLogout = (event) => {
    event.preventDefault();
    this.props.logout();
  }
  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }
  render() {
    return (
      <div className={`account-widget ${this.props.className}`}>
        <div className={classnames('account-badge float-right slide-icon', this.props.status)} onClick={this.handleShow}>
          {this.props.image !== '' ?
            <img className='img-fluid' src={this.props.image} alt="user" title="Main Menu" draggable={false} /> : <BadgeIcon icon='fa-3x fa-user-circle' />
          }
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.account.name || 'I am a Bug!'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AccountModal account={this.props.account} list={this.list} />
            <span className="list-group"><button title="Logout" className="list-group-item" onClick={this.handleLogout}>Logout</button></span>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

/**
  <div className={`slide-icon {props.status}`} onClick={props.next}>
    <i className={`fa fa-3x fa-user-circle ` {props.hideImage}}></i>
    <img className={props.hideImage} src={props.image} alt="user" title="user thumbnail" draggable="false"/>
  </div>
 */

AccountWidget.propTypes = {
  className: PropTypes.string,
  next: PropTypes.func,
  logout: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  hideImage: PropTypes.bool,
  account: PropTypes.object.isRequired,
  tripCount: PropTypes.any,
  itemCount: PropTypes.any,
  packCount: PropTypes.any
};

AccountWidget.defaultProps = {
  className: '',
  image: '',
  status: 'offline',
  hideImage: false,
  next: () => false
};

export default AccountWidget;
