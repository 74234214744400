
/**
 * I'm not as helpful as ai seem, at least when you send me a bad compare
 * @param {*} test 
 * @param {*} compare 
 */
export const coerceTypes = (test, compare) => {
  let out = { ...compare };
  // eslint-disable-next-line array-callback-return
  Object.keys(test).map(name => {
    switch (typeof compare[name]) {
      case 'string':
        out[name] = String(test[name]);
        break;
      case 'number':
        out[name] = Number(test[name]);
        break;
      case 'function':
        break;
      case 'object':
        if (Array.isArray(compare[name])) {
          if (!Array.isArray(test[name])) {
            out[name] = [...out[name], test[name]];
          } else {
            //do we append if they are two arrays or merge?
            out[name] = test[name];
          }
        } else {
          //assume it really is an object
          console.log('object coercion');
        }
        break;
      default:
        break;
    }
  });
  return out;
}
